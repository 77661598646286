.nixiotooltip {
  //all: inherit;
  z-index: 2;
  line-height: 1.3;
  padding-left: 5px;

  .tooltiptext-regular {
    visibility: hidden;
    background-color: white;
    border-width: 1px;
    border-color: black;
    border-style: solid;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.7);
    color: #000000;
    border-radius: 3px;
    padding: 5px 5px;
    position: absolute;
    bottom: 0%;
    left: 0%;
    z-index: 2;
    transition: opacity 0.3s;
    width: max-content;
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
    &:hover {
      visibility: visible;
      opacity: 1;
    }
  }

  .tooltiptext {
    visibility: hidden;
    background-color: white;
    border-width: 1px;
    border-color: black;
    border-style: solid;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.7);
    color: #000000;
    border-radius: 3px;
    padding: 5px 5px;
    position: absolute;
    z-index: 2;
    bottom: 80%;
    left: 0%;
    transition: opacity 0.3s;
    width: max-content;
    max-width: 200px;
    text-wrap: wrap;
    max-height: 150px;
    overflow: auto;

    & > tr td {
      background-color: inherit;
      border-width: 0px;
    }

    td {
      vertical-align: top;
    }

    &:hover {
      visibility: visible;
      opacity: 1;
    }
  }

  &:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
  &:hover .tooltiptext-regular {
    visibility: visible;
    opacity: 1;
  }
}

.tooltip-pointer {
  color: #1365d2;
  display: inline;
}

.nixiotooltip-time {
  text-wrap: nowrap;
}

