.line {
    width: 100%;
    height: 0;
    margin: 0;
    border: solid 1px #eef0f0;
}

.sun-editor-editable {
    padding: 0;
}

.show-less {
    max-height: 60px;
    overflow-y: hidden;
}

mark{
    padding: 0;
    display: inline;
    background: #a1d8fc;
}

.notes {
    overflow-y: scroll;
    width: 100%;

    &-categories {
        display: flex;
        justify-content: start;
    }

    &-search {
        color: #495057;
        background-color: #eeeef0;
        background-clip: padding-box;
        border-radius: 1rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, font-size 0.15s ease-in-out;
        margin: 0.5rem;
        padding: 0 0.2rem;


        &:focus-within {
            box-shadow: 0 0 0 0.2rem rgba(102, 16, 242, 0.25);
        }

        /*
        &:hover {
            outline: 0 !important;
            border-color: initial;
            box-shadow: 1px 1px 2px darken(#eeeef0, 20%);
        }
        */

        input {
            border-width: 0;
            width: 100%;
            padding: 0.375rem 0.5rem;
            background-color: transparent;

            &+.underline {
                transition: opacity 0.15s ease-in-out;
                opacity: 0;
                height: 0;
                width: 0;
            }

            &:focus {
                outline: 0 !important;
                border-color: initial;
                box-shadow: none;

/*                &+.underline {
                    height: 1px;
                    width:calc(100% - 2rem);
                    background: $color-link;
                    opacity: 1;
                    position: absolute;
                    left: 1rem;
                    bottom: 5px;
                }*/
            }

        }

        button {
            border-width: 0;
            background: transparent;
            padding: 0 0.5rem;

            &:focus {
                box-shadow: none;
            }

            i {
                font-size: 1.2rem;
                vertical-align: middle;

                &.cancel {
                    color: #aaaaaa;

                    &:hover {
                        color: darken(#aaaaaa, 15%);
                    }
                }

                &.search {
                    color: $color-purple-1;

                    &:hover {
                        color: darken($color-purple-1, 15%);
                    }
                }

                &:hover {
                    font-size: 1.23rem;
                }
            }
        }
    }

    &-category {
        flex-grow: 1;
        padding: 0.5rem;
        font-weight: 700;
        background: transparent;
        border: none;
        border-bottom: 2px solid $gray-300;
        outline: none !important;

        &-private.active {
            background: $color-magenta-light;
            border-bottom-color: $color-purple;

            i {
                color: $color-purple !important;
                border-color: $color-purple !important;
            }
        }

        &-public.active {
            background: $color-sky-light;
            border-bottom-color: $color-link;

            i {
                color: $color-link !important;
                border-color: $color-link !important;
            }
        }

        &.active {
        }

        i {
            width: 2rem;
            height: 2rem;
            line-height: calc(2rem - 4px);
            color: $gray-500;
            border: 2px solid $gray-500;
            border-radius: 100%;
        }
    }

    &-new {
        display: block;
        width: 100%;
        padding: 0 1rem 1rem;

        &-private {
            background: $color-magenta-light;
        }

        &-public {
            background: $color-sky-light;
        }

        textarea {
            width: 100%;
            height: 100px;
            padding: 0.5rem;
            border: 1px solid $gray-300;
            resize: none;
        }
    }

    &-list {
        padding: 0 1rem;
    }

    .note {
        margin: 0.5rem 0;

        &-close {
            float: right;
            width: 2rem;
        }

        &-heading {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &-title {
            margin: 0 0 10px 1.5px;
            font-family: "Montserrat", sans-serif;
            font-size: 18px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.39;
            letter-spacing: normal;
            text-align: left;
            color: #2b2e34;
            margin-right: 0.5rem;

            &-date {
                font-size: 14px;
                font-weight: 500;
            }
        }

        &-author {
            padding: 0 0.25rem;
            margin-right: auto;
            color: white;
            font-weight: 700;
            font-size: 0.625rem;
            background: $color-orange;

            &-me {
                background: $color-purple;
            }
        }

        &-edit {
            font-weight: 400;
            color: $color-link;
        }

        &-read-more {
            display: inline-block;
            margin-bottom: 10px;
            color: #007aff !important;
            cursor: pointer;
        }
    }
}
