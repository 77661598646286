.activity {

	&-title {

		i {
			margin-right: 0.5rem;
		}

		&-magenta i {color: $color-magenta;}
		&-green i {color: $color-green-vivid;}
		&-blue i {color: $color-sky;}
	}

	&-chart {
		margin-bottom: 1rem;
	}

	&-stats {

		.col {
			padding: 0 0.5rem;
		}

		&-metric {
			margin-bottom: 0;
			font-weight: 500;
			font-size: 0.875rem;
			line-height: 1;

			&-badge {

				&::before {
					content: "";
					display: inline-block;
					vertical-align: top;
					width: 1em;
					height: 1em;
					margin-right: 0.25rem;
					border-radius: 100%;
				}

				&-magenta::before {background: $color-magenta;}
				&-green::before {background: $color-green-vivid;}
				&-sky::before {background: $color-sky;}
				&-sky-light::before {background: $color-sky-light;}
			}
		}

		&-value {
			font-weight: 500;

			span {
				font-weight: 400;
				font-size: 2em;
			}
		}
	}
}