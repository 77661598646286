.patient-menu {
    position: sticky;
    z-index: 1000;
    top: 0;
    padding-top: 0.5rem;
    background: $page-bg;
    overflow-x: auto;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

    @media (min-width: map-get($grid-breakpoints, md)) {
        padding-bottom: 0.5rem;
    }

    @media (min-width: map-get($grid-breakpoints, lg)) {
        padding-right: 2.5rem;
    }

    &-loader {
        display: flex;
        align-items: center;
        height: 3rem;
    }

    &-panel-open {
        padding-right: 200px;
    }

    .row {
        display: flex;
        flex-direction: column;
        justify-content: stretch;

        @media (min-width: map-get($grid-breakpoints, md)) {
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
        }
        @media (min-width: map-get($grid-breakpoints, xl)) {
        }
    }

    &-info {
        display: flex;
        justify-content: stretch;
        align-items: center;
        padding: 0.7rem 1rem;
        background: $card-bg;
        border-radius: 10px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

        &.no-padding {
            padding: 0px !important;
            background-color: transparent !important;
            box-shadow: none !important;
        }
    }

    &-image {
        width: 3rem;
        height: 3rem;
        margin-right: 1rem;
        text-align: center;
        font-size: 2rem;
        line-height: 3rem;
        border-radius: 10rem;
        object-fit: cover;
    }

    &-details {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        flex-grow: 1;
        font-size: 0.75rem;

        @media (min-width: map-get($grid-breakpoints, xl)) {
            font-size: 0.875rem;
        }
    }

    &-detail {
        flex-grow: 0;

        &-full {
            width: 100%;
            flex-basis: 100%;
            flex-shrink: 0;
            font-weight: 700;
            h4 {
                margin-bottom: 0px;
            }
        }

        &::after {
            content: '|';
            margin-left: 0.25em;
            margin-right: 0.25em;
        }

        &-full,
        &:last-child {

            &::after {
                display: none;
            }
        }
    }

    &-name {
        margin: 0.25rem 0 0.5rem;
        font-weight: 700;

        &:last-child {
            margin: 0;
        }
    }

    &-status {
        margin-bottom: 0;
        font-size: 0.75rem;
        line-height: 1.25;
        color: $gray-600;

        @media (min-width: map-get($grid-breakpoints, md)) {
            font-size: 1rem;
        }
    }

    &-items {
        display: flex;
        justify-content: center;
        list-style: none;
        padding: 0;
        margin: 0;

        @media (min-width: map-get($grid-breakpoints, lg)) {
            justify-content: flex-start;
            transform: translate(-15%, 0%);
        }
        @media (min-width: map-get($grid-breakpoints, xl)) {
            justify-content: center;
        }
    }

    &-item {
        padding: 0.5rem 0;
        margin: 0 0 0 0.125rem;
        a {
            font-size: 24px !important;
            .badge-red {
                top: -0.1rem !important;
                right: unset !important;
            }

        }

        &:first-child {
            margin: 0;
        }

        @media (min-width: map-get($grid-breakpoints, sm)) {
            margin: 0 0 0 0.25rem;
        }
        @media (min-width: map-get($grid-breakpoints, lg)) {
            margin: 0 0 0 0.5rem;
        }
        @media (min-width: map-get($grid-breakpoints, xl)) {
        }
    }

    a {
        display: block;
        padding: 0.125rem 20px 0.5rem 20px;
        font-size: 0.875rem;
        text-decoration: none !important;
        line-height: 1;
        color: $color-purple;
        border-radius: 2rem;
        outline: none;
        line-height: 40px;
        font-weight: bold;

        @media (min-width: map-get($grid-breakpoints, sm)) {
            padding: 0.125rem 20px 0.25rem 20px;
        }
        @media (min-width: map-get($grid-breakpoints, lg)) {
            padding: 0.125rem 20px 0.25rem 20px;
            font-size: 1.15rem;
        }
        @media (min-width: map-get($grid-breakpoints, xl)) {
            font-size: 1.25rem;
        }

        &:hover,
        &:focus,
        &.active {
            color: white;
            background: $color-purple-1;
        }

        &.active {
            cursor: default;
        }
    }
}