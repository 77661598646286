.calendar {
    height: 75vw;
    background: $background-white;

    @media (min-width: map-get($grid-breakpoints, md)) {
        min-height: 640px;
        height: calc(100vh - 190px);
    }

    &-container {
        position: relative;
        height: calc(100% - 64px);
    }

    &-loader {
        margin-top: 2rem;
        text-align: center;

        & + div {
            opacity: 0;
        }
    }

    &-toolbar {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 1rem;

        > div {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-grow: 1;
            flex-basis: 0;

            &:first-child {
                justify-content: flex-start;
            }
            &:last-child {
                justify-content: flex-end;
            }
        }
    }

    &-dropdown {
        position: relative;
        margin-left: 1rem;
    }

    &-filters {
        position: absolute;
        z-index: 1000;
        top: 100%;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 1rem;
        background: white;
        border-radius: 0.25rem;
        box-shadow: $box-shadow;

        .btn + .btn {
            margin-top: 1rem;
        }
    }

    &-legend {

        &-button {
            display: block;
            height: 1rem;
            padding: 0 0.75rem;
            margin: 0;
            font-size: 0.875rem;
            line-height: 1rem;
            color: $color-grey-2;
            background: transparent;
            border: none;
            outline: none !important;

            &:hover,
            &:focus {
                color: black;
            }
        }
        
        &-date {
            padding: 0 0.25rem;
            text-align: center;
        }

        &-month {
            min-width: 5.5em;
            margin: 0 0 0.25rem;
            line-height: 1rem;
        }

        &-year {
            margin: 0;
            font-size: 0.75rem;
            line-height: 1;
            color: $color-grey-2;
        }
    }
}

.rbc {

    &-header {
        padding: 0.5rem;
        text-align: right;
        font-weight: 400;
        font-size: 0.75rem;
        text-transform: uppercase;
        color: $color-grey-2;
    }

    &-date-cell {
        padding: 0.5rem 0.5rem 0.125rem;
        font-size: 0.75rem;
        color: $color-grey-2;

        &.rbc-off-range {
            color: $color-grey-3;
        }
    }

    &-row-segment {
        padding: 0 0.25rem;
    }

    &-event {
        padding: 0.25rem;
        color: inherit;
        background: transparent;

        &:focus {
            outline: none;
        }

        &.rbc-selected {
            background: transparent;
        }

        &.active {
            background: white;
            box-shadow: $box-shadow;
        }

        &-content {
            padding-left: 1.25rem;
            text-indent: -1.25rem;
            //text-transform: capitalize;
            font-size: .75rem;
            white-space: normal;

            &::before {
                content: '';
                position: relative;
                top: 2px;
                display: inline-block;
                width: 1em;
                height: 1em;
                margin-right: 0.25rem;
                background: white;
                border-radius: 1em;
            }
        }
        
        &.imaging .rbc-event-content {
            text-transform: none;
        }

        &.lab .rbc-event-content::before {background: $color-purple;}
        &.imaging .rbc-event-content::before {background: $color-green;}
        &.intervention .rbc-event-content::before {background: $color-red;}
        &.pro .rbc-event-content::before {background: $color-sky;}
        &.md .rbc-event-content::before {background: $color-red-dark;}
        &.hospitalvisits .rbc-event-content::before {background: $color-black;}
    }

    &-today {
        background: $color-sky-light;
    }

    &-show-more {
        padding-top: 0.25rem;
        font-size: .75rem;
        background: transparent;
    }

    &-header,
    &-month-view,
    &-month-row,
    &-day-bg {
        border-color: $page-bg;
    }

    &-off-range-bg {
        background: transparent;
    }

    &-overlay {
        padding: 0 1rem 0.5rem;
        border-radius: 0.25rem;
        box-shadow: $box-shadow;
        opacity: 0;

        &.visible {
            opacity: 1;
        }

        &-header {
            padding: 0.5rem 0;
            margin: 0 0 0.5rem;
            font-weight: 700;
            font-size: 1rem;
            border-bottom: 1px solid $color-grey-3;
        }

        > * + * {
            margin-top: 0;
        }
    }
}
