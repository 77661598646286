.document {
    overflow-y: auto;
    overscroll-behavior: none;

    &-loading &-content {
        opacity: 0;
    }

    &-toolbar {
        position: sticky;
        z-index: 1000;
        top: 0;
        padding: 0.5rem 0;
        background: $gray-700;

        [class*="col"] {
        }

        &-pagination {
            display: flex;
            justify-content: center;

            .input-group {
                width: auto;
            }

            .input-group-prepend {
                margin-right: 0;
            }

            .input-group-append {
                margin-left: 0;
            }
        }

        &-search {
            display: flex;
            justify-content: flex-end;

            &-value  {
                position: relative;
            }

            &-counter {
                position: absolute;
                top: 0;
                right: 0;
                padding: 0.5rem;
                font-size: .75rem;
                opacity: .5;
                cursor: default;
                pointer-events: none;
            }
        }

        &-button {
        }

        &-input {
            min-width: 0;
            max-width: none;

            &-page {
                width: 3rem !important;
                text-align: center;
            }

            &-search {
                width: 10rem !important;
            }
        }

        input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {-webkit-appearance: none; margin: 0;}
        input[type=number] {-moz-appearance: textfield;}
    }

    &-content {
        overflow-x: auto;
        background-color: $navbar-bg;
    }

    &-search {

        &-result {
            display: inline-block;
            background: yellow;
            outline: 2px solid yellow;

            &-current {
                background: orange;
                outline: 2px solid orange;
                animation: pop 0.1s ease-out;
            }

            &-noanimation {
                animation: none !important;
            }
        }
    }

    .react-pdf {

        &__Document {
            text-align: center;
        }

        &__Page {
            margin: 0 auto;
            overflow: hidden;

            &:not(:last-child) {
                margin-bottom: 1rem;
            }

            &__canvas,
            &__svg {
                margin: 0 auto;
                background: white;
                box-shadow: 0 0 0.25rem rgba(0, 0, 0, .1);
            }

            &__textContent {
                user-select: none;
                color: transparent;
                mix-blend-mode: multiply;
            }

            &--overzoom .react-pdf__Page__textContent {
                top: 0 !important;
                left: 0 !important;
                transform: none !important;
            }

            &__annotations {
                user-select: none;
            }
        }
    }

    &-scaled {

        .react-pdf {

            &__Page {
                margin: 1rem auto;
            }
        }
    }
}

@keyframes pop {
    0% {transform: scale(3)}
    100% {transform: scale(1)}
}


.document-view {

    &-loading &-content {
        opacity: 0;
    }

    &-toolbar {
        padding: 0.5rem 0;
        background: $gray-700;

        [class*="col"] {
        }

        &-pagination {
            display: flex;
            justify-content: center;

            .input-group {
                width: auto;
            }

            .input-group-prepend {
                margin-right: 0;
            }

            .input-group-append {
                margin-left: 0;
            }
        }

        &-search {
            display: flex;
            justify-content: flex-end;

            &-value  {
                position: relative;
            }

            &-counter {
                position: absolute;
                top: 0;
                right: 0;
                padding: 0.5rem;
                font-size: .75rem;
                opacity: .5;
                cursor: default;
                pointer-events: none;
            }
        }

        &-button {
        }

        &-input {
            min-width: 0;
            max-width: none;

            &-page {
                width: 3rem !important;
                text-align: center;
            }

            &-search {
                width: 10rem !important;
            }
        }

        input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {-webkit-appearance: none; margin: 0;}
        input[type=number] {-moz-appearance: textfield;}
    }

    &-content {
        min-height: 30vh;
        height: calc(100vh - 56px);
        overflow-y: auto;
        overscroll-behavior: none;
    }

    &-search {

        &-result {
            display: inline-block;
            background: yellow;
            outline: 2px solid yellow;

            &-current {
                background: orange;
                outline: 2px solid orange;
                animation: pop 0.1s ease-out;
            }

            &-noanimation {
                animation: none !important;
            }
        }
    }

    .react-pdf {

        &__Document {
            text-align: center;
        }

        &__Page {
            margin: 0 auto;
            overflow: hidden;

            &:not(:last-child) {
                margin-bottom: 1rem;
            }

            &__canvas,
            &__svg {
                margin: 0 auto;
                background: white;
                box-shadow: 0 0 0.25rem rgba(0, 0, 0, .1);
            }

            &__textContent {
                user-select: none;
                color: transparent;
                mix-blend-mode: multiply;
            }

            &--overzoom .react-pdf__Page__textContent {
                top: 0 !important;
                left: 0 !important;
                transform: none !important;
            }

            &__annotations {
                user-select: none;
            }
        }
    }

    &-scaled {

        .react-pdf {

            &__Page {
                margin: 1rem auto;
            }
        }
    }
}

.document-info-toolbar {
    width: 100%;
    padding: 8px 14px;
    position: relative;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: $page-bg;
    gap: 1rem;

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }

    .info-left {
        display: inline-flex;
        align-items: center;
        gap: 0.8rem;

        .prop {
            display: inline-flex;
            flex-direction: column;

            .name {
                font-size: 0.9rem;
                font-weight: 400;
            }

            .value {
                font-weight: 500;
                max-width: 10rem;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }
    }

    .info-right {
        display: inline-flex;
        align-items: center;
        flex-direction: row;
        gap: 0.8rem;
    }

    .btn.icon-link {
        padding: 0.2rem;
        i {
        }
    }
}

.document-info-toolbar {
    &.info-controls {
        background-color: #fff;
    }
}

.document-view-header {
    display: flex;
    flex-direction: row;
    align-items: stretch;

    .header-logo {
        display: flex;
        background-color: #4830d3;
        align-items: center;
        padding: 10px;

        img {
            height: 80px;
        }
    }

    .header-info {
        display: flex;
        flex-direction: column;
        width: 100%;

        .document-info-toolbar {
            padding: 2px 5px 2px 5px;
        }
    }

    .patient-menu-info {
        background-color: $card-bg;
        padding: 0.5rem 1rem;
        background: $card-bg;
        border-radius: 10px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

        &.no-background {
            background-color: unset;
            background: unset;
            box-shadow: unset;
        }

        .patient-menu-details {
            font-size: 1rem;
            display: block;
        }

        .patient-menu-image {
            width: 3rem;
        }

        i.patient-menu-image {
            width: auto;
        }

    }
}

.react-pdf__Page {
    width: auto !important;
}