.modal {
    &.fade.show:nth-of-type(odd) {
        z-index: 10004 !important;
    }

    &-backdrop.stack.show{
        z-index: 10003 !important;
    }

    /* modal backdrop fix */
    &:nth-of-type(even) {
        z-index: 1052 !important;
    }

    &-backdrop.show:nth-of-type(even) {
        z-index: 10004 !important;
    }

    .form {
        max-width: 100%;
    }
}

.root-modal.modal {
    position: fixed;
    z-index: 10002;
    display: block;
    height: 100vh;

    .modal-open {
        overflow: visible;
    }

    .modal-backdrop {
        z-index: 10001;
    }

    .modal-dialog {
        max-width: map-get($grid-breakpoints, xl);
        height: 100%;
        padding: 5.75rem 0.5rem;
        margin: 0 auto;

        @media (min-width: map-get($grid-breakpoints, sm)) {
            padding: 5.75rem 1rem;
        }
        @media (min-width: map-get($grid-breakpoints, lg)) {
            padding: 1rem 5.75rem;
        }

        &-small {
            max-width: map-get($grid-breakpoints, sm);
        }

        &-medium {
            max-width: map-get($grid-breakpoints, lg);
        }
    }

    .modal-content {
        max-height: 100%;
        border: none;
        border-radius: 10px;
    }

    .modal-header {
        flex-shrink: 0;
        align-items: center;
        padding: 1rem;
        border: none;
    }

    .modal-title {

        span {
            font-weight: 400;

            &::before {
                content: ' | ';
            }
        }

        .btn {
            margin-left: 1rem;
        }
    }

    .modal-toolbar {
        display: flex;
        align-items: center;

        > * {
            margin-left: 0.5rem;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    .modal-tabs {

        &-items {
            display: flex;
            justify-content: stretch;
            align-items: flex-start;
        }

        &-item {
            flex-grow: 1;
            padding: 0.5rem 1rem;
            margin: 0;
            text-align: left;
            font-weight: 700;
            font-size: 1.25rem;
            background: $gray-200;
            border: none;
            outline: none !important;

            &.active {
                background: white;
            }
        }
    }

    .modal-body {
        padding: 0 1rem 1rem;
        overflow-y: auto;
        overscroll-behavior: none;

        &-medical-id {

            .table {

                th, td {

                    &:first-child {
                        width: 6.5em;
                    }
                    &:nth-child(2):nth-last-child(3) {
                        min-width: 10em;
                    }
                    &:nth-child(3):nth-last-child(2) {
                        width: 10em;
                    }
                    &:nth-child(4):last-child {
                        width: 9em;
                    }
                }
            }
        }

        form {
            padding-top: 1rem;
        }

        *:last-child {
            margin-bottom: 0;
        }
    }

    .modal-close {
        position: absolute;
        z-index: 1050;
        width: 3.75rem;
        height: 3.75rem;
        padding: 0;
        margin: 0;
        font-size: 2rem;
        background: transparent;
        border: 0.25rem solid white !important;
        border-radius: 100%;

        @media (max-width: map-get($grid-breakpoints, lg) - 1) {
            bottom: 100%;
            right: 0;
            margin-bottom: 1rem;
        }

        @media (min-width: map-get($grid-breakpoints, lg)) {
            top: 0;
            left: 100%;
            margin-left: 1rem;
        }

        &:hover,
        &:focus {
            color: $color-purple;
            background: white;
        }
    }

    .modal-body {

        &-medical-id {

            h6 + p {
                margin-bottom: 2rem;
            }
        }
    }

    .modal-ccm-notes {

        &-add {

            .badge {
                font-weight: 700;
                font-size: 1rem;
            }
        }


        &-gestures {
            padding: 0.5rem 0;
            margin: 2rem 0 1rem;
            background: rgba(0, 0, 0, .025);
            border-top: 2px solid $navbar-bg;

            user-select: none;
            cursor: pointer;

            span {
                padding: 0 1rem;
            }

            i {
                margin-right: 0.25rem;
            }
        }
    }
}

.confirmation-modal {
    max-width: 40%;
    margin: 25% 30%;
    border-radius: 6px;

    .modal-dialog{
        padding: 0.5rem;
    }

    .modal-header {
        padding: 1rem 0.5rem 0.5rem;
        justify-content: center;
    }

    .modal-footer{
        justify-content: center;
    }
}

.react-draggable {
    position: fixed !important;
    z-index: 10000 !important;
    border-radius: 6px;
}

.audio-recording-modal {
    &.modal-dialog {
        max-width: 700px;

        .modal-body {
            padding: 16px;
            text-align: center;

            .meeting-recording {
                margin: 10px 0;
            }
        }
    }
}

.fullscreen-modal {
    &.modal-dialog {
        width: 100% !important;
        max-width: 100% !important;
    }
}

.confirm-modal {
    .modal-footer {
        .btn {
            min-width: 8ch;
        }
    }
}
