.patient-bar {
    position: sticky;
    z-index: 1000;
    top: 0;
    padding: 0.5rem 0;
    background: $patient-bar-bg;

    .container-fluid {
        display: flex;
        align-items: center;
        min-height: 2.5rem;
    }

    &-image {
        width: 2.5rem;
        height: 2.5rem;
        margin-right: 1rem;
        text-align: center;
        font-size: 1rem;
        line-height: 2.5rem;
        border-radius: 10rem;
        object-fit: cover;
        background: $navbar-bg;
    }

    &-detail {

        &:not(:first-of-type)::before {
            content: '|';
            margin: 0 0.5rem;
        }

        &:empty {
            display: none;
        }
    }
}