button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
    cursor: pointer;
}

.btn {
    border-radius: 0;

    &-xs,
    &-group-xs > & {
        padding: 0.25rem 1rem;
        font-size: 0.875rem;
        line-height: 1.25;
        border-radius: 0.15rem;
    }

    &-link {
        padding-left: 0;
        padding-right: 0;
        font-weight: 700;
        text-decoration: none;
        color: $color-purple;

        &:disabled {
            color: #AAAAAA !important;
            background-color: unset !important;
        }
        &:hover {
            color: $color-purple !important;
        }
    }

    &-round {
        border-radius: 2rem;
    }

    &-round-6 {
        border-radius: 6px;
    }

    &-no-right-marging {
        margin-right: 0px !important;
    }

    &-red {
        color: var(--bs-body-color);
        background: $color-red;

        &:hover {
            color: var(--bs-body-color);
            background: transparentize($color-red, 0.2);
        }

        &:focus {
            box-shadow: 0 0 0 0.2rem transparentize($color-red, 0.5);
        }
    }

    &-red-dark {
        color: white;
        background: $color-red-dark;

        &:hover {
            color: white;
            background: transparentize($color-red-dark, 0.2);
        }

        &:focus {
            box-shadow: 0 0 0 0.2rem transparentize($color-red-dark, 0.5);
        }
    }

    &-purple {
        color: white;
        background: $color-purple;

        &:hover {
            color: white;
            background: transparentize($color-purple, 0.2);
            box-shadow: 0 0 0 0.2rem transparentize($color-purple, 0.5);
        }

        &:focus {
            box-shadow: 0 0 0 0.2rem transparentize($color-purple, 0.5);
        }
    }
    &-lightish-purple {
        color: white;
        background: $color-lightish-purple;

        &:hover {
            color: white;
            background: transparentize($color-lightish-purple, 0.2);
            box-shadow: 0 0 0 0.2rem transparentize($color-lightish-purple, 0.5);
        }

        &:focus {
            box-shadow: 0 0 0 0.2rem transparentize($color-lightish-purple, 0.5);
        }
    }
    &-algae-green {
        color: white;
        background: $color-algae-green;

        &:hover {
            color: white;
            background: transparentize($color-algae-green, 0.2);
            box-shadow: 0 0 0 0.2rem transparentize($color-algae-green, 0.5);
        }

        &:focus {
            box-shadow: 0 0 0 0.2rem transparentize($color-algae-green, 0.5);
        }
    }
    &-dusty-orange {
        color: white;
        background: $color-dusty-orange;

        &:hover {
            color: white;
            background: transparentize($color-dusty-orange, 0.2);
            box-shadow: 0 0 0 0.2rem transparentize($color-dusty-orange, 0.5);
        }

        &:focus {
            box-shadow: 0 0 0 0.2rem transparentize($color-dusty-orange, 0.5);
        }
    }
    &-deep-sky-blue {
        color: white;
        background: $color-deep-sky-blue;

        &:hover {
            color: white;
            background: transparentize($color-deep-sky-blue, 0.2);
            box-shadow: 0 0 0 0.2rem transparentize($color-deep-sky-blue, 0.5);
        }

        &:focus {
            box-shadow: 0 0 0 0.2rem transparentize($color-deep-sky-blue, 0.5);
        }
    }
    &-deep-sky-blue-border {
        color: $color-deep-sky-blue;
        background: rgba(0, 136, 255, 0.2);
        border-style: solid !important;
        border-width: 1px !important;
        border-color: $color-deep-sky-blue !important;

        &:hover {
            color: $color-deep-sky-blue;
            background: rgba(0, 136, 255, 0.2);
            box-shadow: 0 0 0 0.2rem transparentize($color-deep-sky-blue, 0.5);
        }

        &:focus {
            box-shadow: 0 0 0 0.2rem transparentize($color-deep-sky-blue, 0.5);
        }
        &.selected {
            color: white;
            background-color: $color-deep-sky-blue;
        }
    }
    &-brownish-grey {
        color: white;
        background: $color-brownish-grey;

        &:hover {
            color: white;
            background: transparentize($color-brownish-grey, 0.2);
            box-shadow: 0 0 0 0.2rem transparentize($color-brownish-grey, 0.5);
        }

        &:focus {
            box-shadow: 0 0 0 0.2rem transparentize($color-brownish-grey, 0.5);
        }
    }
    &-dark-indigo {
        color: white;
        background: $color-dark-indigo;

        &:hover {
            color: white;
            background: transparentize($color-dark-indigo, 0.2);
            box-shadow: 0 0 0 0.2rem transparentize($color-dark-indigo, 0.5);
        }

        &:focus {
            box-shadow: 0 0 0 0.2rem transparentize($color-dark-indigo, 0.5);
        }
    }
    &-pinkish-red {
        color: white;
        background: $color-pinkish-red;

        &:hover {
            color: white;
            background: transparentize($color-pinkish-red, 0.2);
            box-shadow: 0 0 0 0.2rem transparentize($color-pinkish-red, 0.5);
        }

        &:focus {
            box-shadow: 0 0 0 0.2rem transparentize($color-pinkish-red, 0.5);
        }
    }
    &-very-light-pink {
        color: black;
        background: $color-very-light-pink;

        &:hover {
            color: transparentize(black, 0.2);
            background: transparentize($color-very-light-pink, 0.2);
            box-shadow: 0 0 0 0.2rem transparentize($color-very-light-pink, 0.5);
        }

        &:focus {
            box-shadow: 0 0 0 0.2rem transparentize($color-very-light-pink, 0.5);
        }
    }

    &-white {
        background-color: white !important;
        color: $color-purple !important;
        border-width: 1px !important;
        border-color: $color-purple !important;
        text-transform: none !important;
        &:hover {
            background-color: white;
            color: $color-purple;
            box-shadow: 0 0 0 0.2rem transparentize($color-purple, 0.5);
        }
    }

    &-white-invert {
        background-color: white !important;
        color: $color-purple !important;
        border-width: 1px !important;
        border-color: $color-purple !important;
        text-transform: none !important;
        &:hover {
            background-color: $color-purple !important;
            color: white !important;
            box-shadow: 0 0 0 0.2rem transparentize($color-purple, 0.5);
        }
    }

    &-tab {
        background-color: $color-ice-blue;
        color: $color-brownish-grey;
        border-width: 0px;
        border-radius: 15px;
        font-weight: bold;
        padding: 3px 10px 3px 10px;

        &:hover {
            background-color: $color-ice-blue;
            color: $color-brownish-grey;
            box-shadow: 0 0 0 0.2rem transparentize($color-ice-blue, 0.5);
        }

        &:disabled {
            font-weight: bolder;
            background-color: $color-timeline-selected-background !important;
            color: $color-timeline-selected !important;
            opacity: unset !important;
        }
    }

    &-timeline-active {
        color: $color-purple;
        background: white;
        height: 32px;
        line-height: 27px;
        border: 1px solid $color-purple;
        padding-top: 2px;
        padding-bottom: 2px;
        padding-left: 6px;
        padding-right: 6px;
        width: 45%;

        &.right {
            border-top-left-radius: 0px;
            border-top-right-radius: 6px;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 6px;
            margin-right: 10px;
        }
        &.left {
            border-top-left-radius: 6px;
            border-top-right-radius: 0px;
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 0px;
            margin-left: 0px;
        }

        &:hover {
            color: white;
            background: $color-purple;
        }
    }

    &-timeline-inactive {
        color: $color-timeline-selected;
        background: $color-timeline-selected-background;
        height: 32px;
        line-height: 27px;
        border: 1px solid $color-timeline-selected;
        padding-top: 2px;
        padding-bottom: 2px;
        padding-left: 6px;
        padding-right: 6px;
        width: 45%;
        font-weight: bolder;

        &.right {
            border-top-left-radius: 0px;
            border-top-right-radius: 6px;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 6px;
            margin-right: 10px;
        }
        &.left {
            border-top-left-radius: 6px;
            border-top-right-radius: 0px;
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 0px;
            margin-left: 0px;
        }
    }

    &.heigh-30 {
        height: 30px;
        padding: 3.5px 20px 5.5px;
        border: 0px;
    }

    &:disabled {
        background-color: $color-brown-grey;
        color: #FFFFFF;
        font-weight: 500;
        opacity: unset;
    }

    &.radius-6 {
        border-radius: 6px;
    }
}

.btn-outline-purple {
    color: white;
    border-color: $color-purple;
    background-color:$color-purple;

    &:hover{
        color: white;
        border-color: $color-purple;
        background-color:$color-purple;
        box-shadow: 0 0 0 0.2rem transparentize($color-purple, 0.5);
    }
}

.btn-clean {
    color: black;
    border-color: transparent;
    background-color:transparent;

    &:hover{
        color:black;
        background-color:transparent;
        border-color:transparent;
    }
}

.btn-clean-36-36 {
    padding: 0px;
    border: 0px;
    margin: auto;
    width: 36px;
    height: 36px;
}