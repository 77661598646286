.table {
    tbody {
        tr {
            td {
                &.error {
                    border: 2px solid $color-red;
                }
            }
        }
    }
}

.patient-table {
    border: 1px solid $gray-300;

    &-container {
        overflow-x: auto;
    }

    &-subtle {
        border: none;
    }

    &-dark-column {

        &-1 {td:nth-child(1) {background: $gray-200;}}
    }

    th,
    td {
        vertical-align: middle;
        border: none;
    }

    &-align-top {

        td {
            vertical-align: top;
        }
    }

    thead {

        th {
            vertical-align: top;
            font-weight: 700;
            border-bottom: 1px solid $gray-300;

            span {
                //font-weight: 400;
            }

            .btn-sort {
                margin-left: 0.25rem;
                outline: none !important;
            }
        }

        .th {
            color: white;

            &-orange {background: $color-orange;}
            &-sky {background: $color-sky;}
            &-purple {background: $color-purple;}
        }
    }

    &-subtle thead {

        th {
            padding-bottom: 0.5rem;
            font-weight: 400;
            font-size: 0.875rem;
            color: $gray-600;
            border-bottom-color: $gray-600;
        }
    }

    tbody {

        tr {
            //border-bottom: 1px solid $gray-600;
            background-color: $color-table-patient-list-background;

            &:last-child {
                border-bottom: none;
            }
            &:hover {
                background-color: $color-table-patient-list-hover;
            }
        }

        td {

            &.color-red {
                padding-top: 0.125rem;
                padding-bottom: 0.125rem;
                font-size: 1.5rem;
                color: $color-red;
            }

            h6 {
                margin-bottom: 0;
            }

            img {
                width: 1.25rem;
            }
        }
    }

    &-subtle tbody {

        tr {
            border-bottom-color: $gray-300;
        }
    }
}

table.file-group-table {
    border: 0;
    overflow: scroll;
    border-collapse: collapse;

    thead {
        table-layout: fixed;
        display: block;
        border-bottom: solid 1px #707070;

        th {
            font-weight: 500;
            border: none;
            padding: 0.5rem 0;

            &:nth-child(2) {
                max-width: 7rem;
                width: 7rem;
            }
            &.show-faculty:nth-child(2) {
                max-width: 6.5rem;
                width: 6.5rem;
            }

            &.read-actions {
                width: 7px;
            }

            .header-container {
                display: flex;
                border-radius: 1rem;
                padding: 0.2rem 0.7rem;
                max-width: 100%;
                position: relative;
                align-content: center;
                justify-content: space-between;

                .header {
                    display: inline-block;
                    max-width: 100%;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    vertical-align: middle;
                    padding-right: 0.7em;
                }

                i {
                    position: absolute;
                    right: 0.4rem;
                    top: 50%;
                    margin: 0;
                    -ms-transform: translateY(-50%);
                    transform: translateY(-50%);
                }

                &.sorted {
                    background-color: #edefef;
                }
            }
        }
    }

    tbody {
        height: calc(100vh - 310px);
        overflow-y: auto;
        overflow-x: hidden;
        display: block;

        tr {
            border-bottom: 1px solid rgba(60, 60, 67, 0.36);
            height: 45px;

            &.hover {
                color: $table-hover-color;
                background-color: $color-table-selected-row;
            }

            &.group-title {
                font-weight: 500;
                color: #3d87f9;
                margin-top: 0.5rem;
                border-bottom: solid 1.5px #3d87f9;

                &.selectable {
                    cursor: none;
                }

                &:hover {
                    background-color: inherit;
                }

                td {
                    padding-top: 1rem;
                }
            }

            &.selectable {
                cursor: pointer;

                &.selected, &:hover {
                    background-color: $color-table-selected-row;
                }
            }

            &.expanded-parent {
                border-bottom: none;
            }

            &.new-file {
                font-weight: bold;
            }

            td {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                border-top: none;
                vertical-align: middle;
                padding: 0.4rem;
                max-width: 0;
                background-color: transparent;

                &:nth-child(2) {
                    max-width: 4.5rem;
                    width: 4.5rem;
                }
                &.show-faculty:nth-child(2) {
                    max-width: 6.5rem;
                    width: 6.5rem;
                }

                &.expanded {
                    overflow: auto;
                    white-space: normal;
                    font-size: 0.9rem;
                }

                img {
                    max-height: 50px;
                    max-width: 40px;
                }

                &.read-actions {
                    width: 7px;
                    background-color: $color-brown-grey;
                    &.new-file {
                        background-color: $color-deep-sky-blue;
                    }
                }
            }
        }
    }

    thead, tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
    }

}

.ni-editable-cell {
    width: 100%;
    cursor: text;

    .cell-value {
        min-height: 1rem;
    }

    .cell-empty-value {
        font-size: 0.8em;
        color: #6c757d;
    }
}