.action-menu {
    position: fixed;
    z-index: 1000;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    padding: 0.25rem;
    margin: 0;
    background: $navbar-bg;

    @media (max-width: map-get($grid-breakpoints, lg) - 1) {
        left: 0;
        flex-direction: row;
        justify-content: space-around;
    }

    @media (min-width: map-get($grid-breakpoints, lg)) {
        top: 0;
        flex-direction: column;
        justify-content: flex-start;
        padding-top: 6rem;

        &-open {
            right: 320px;
        }
    }

    @media (min-width: map-get($grid-breakpoints, xl)) {
    }

    &-button {
        position: relative;
        display: block;
        width: 3rem;
        height: 3rem;
        padding: 0;
        margin: 0;
        font-size: 1.25rem;
        line-height: 2rem;
        color: $color-purple;
        background: transparent;
        border: none;
        border-radius: 100%;
        outline: none !important;
        box-shadow: none !important;

        &::after {
            content: attr(data-panel);
            position: absolute;
            top: 0.5rem;
            right: 100%;
            padding: 0.125rem 0.5rem;
            text-transform: capitalize;
            font-size: 0.75rem;
            line-height: 1;
            color: $color-secondary;
            background: white;
            opacity: 0;
            visibility: hidden;
        }

        &:hover {
            color: $color-link;

            &::after {
                opacity: 1;
                visibility: visible;
            }
        }

        &-active {
            background: white;
        }

        @media (min-width: map-get($grid-breakpoints, lg)) {
            margin-bottom: 0.5rem;
        }

        &-badge {
            position: absolute;
            z-index: 1;
            bottom: 2px;
            right: 2px;
            width: 12px;
            height: 12px;
            text-align: center;
            font-weight: 700;
            font-size: 10px;
            line-height: 10px;
            color: white;
            background: red;
            border-radius: 100%;
        }
    }
}