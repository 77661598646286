.password-input {
    position: relative;

  .form-control {
    padding-right: 20px;
  }

  &__group {
    position: relative;
  }

  &__icon {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    cursor: pointer;
  }

  &__strength {
    margin-top: 5px;
  }

  &__strength-indicator {
    width: 100%;
    display: flex;
    gap: 3px;
  }

  &__strength-indicator-bar {
    flex-grow: 1;
    height: 3px;
    background-color: #d8d8d8;
    border-radius: 1.5px;

    &--weak {
      background-color: #f51842;
    }

    &--medium {
      background-color: #ffa200;
    }

    &--strong {
      background-color: #08f;
    }
  }

  &__strength-label {
    width: 100%;
    text-align: right;
    font-size: 10px;
    font-weight: 500;
  }
}

