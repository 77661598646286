.dials {

    .row {
        margin: 0 -0.5rem;

        .row {
            margin: 0;
        }
    }

    .half,
    .col {
        padding: 0 0.5rem;
    }

    .half {
        display: flex;
        flex-direction: column;

        &:first-child {
            width: 60%;
        }

        &:last-child {
            width: 40%;
            border-left: 2px solid $gray-500;
        }
    }

    .col {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &-title {
        margin-bottom: 1rem;
        font-weight: 400;
    }

    &-na {
        margin-top: auto;
        margin-bottom: auto;
    }

    &-note {
        margin-top: 0.5rem;
        font-size: 0.75rem;
        text-align: center;
        text-transform: capitalize;

        @media (min-width: map-get($grid-breakpoints, lg)) {
            font-size: 0.5rem;
        }
        @media (min-width: map-get($grid-breakpoints, xl)) {
            font-size: 0.625rem;
        }
        @media (min-width: $breakpoint-xxl) {
            font-size: 0.75rem;
        }
    }
}