.card {
    margin-bottom: 1rem;
    background: $card-bg;
    border: none;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

    @media (max-width: (map-get($grid-breakpoints, xl) - 1)) {
        font-size: 0.75rem;
    }

    &-timeline {
        background: transparent;

        .row {
            margin-left: -1rem;
            margin-right: -1rem;
        }

        .col {
            padding-left: 1rem;
            padding-right: 1rem;

            &:first-child,
            &:last-child {
                flex-grow: 0;
            }

            &:not(:last-child) {
                margin-bottom: 1.5rem;

                @media (min-width: map-get($grid-breakpoints, md)) {
                    margin-bottom: 0;
                    border-right: 1px solid $gray-500;
                }
            }
        }

        .card-title {
            flex-grow: 0;
            margin-bottom: 0.5rem;
            font-weight: 500;
        }

        .card-toolbar {
            margin-top: 0;

            .btn {
                margin-top: 0;
                margin-bottom: 0.5rem;
            }
        }

        .btn-group {

            .btn {
                min-width: 6.5em;
            }

            .btn-outline-primary {
                background: $color-purple-light;
            }

            .btn-outline-secondary {
                background: white;

                &:hover {
                    color: $primary;
                    background: $color-purple-light;
                    border-color: $primary;
                }
            }
        }

        .card-body {

            @media (max-width: (map-get($grid-breakpoints, lg) - 1)) {
                padding-top: 0;
                padding-bottom: 0;
            }
            @media (min-width: (map-get($grid-breakpoints, lg))) {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    &-caption {
        margin-bottom: 1rem;

        @media (min-width: (map-get($grid-breakpoints, sm))) {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            &-center {
                align-items: center;
            }
        }

        &-activity {

            @media (min-width: (map-get($grid-breakpoints, md))) {

                .col {

                    &:nth-child(2) {
                        text-align: center;
                    }
                    &:last-child {
                        text-align: right;
                    }
                }
            }
        }
    }

    &-title {
        margin: 0;
        flex-grow: 1;
        font-weight: 700;
        font-size: 20px;

        &-linear {
            display: flex;
            flex-wrap: wrap;
            align-items: baseline;
        }

        button {
            padding: 0;
            margin: 0;
            font-weight: inherit;
            color: $color-purple-1;
            text-align: left;
            background: transparent;
            border: none;
            cursor: pointer;
            transition: 0.2s ease-in;

            &:hover,
            &:focus {
                transition: 0.2s ease-in;
                transform: scale(1.1) translateX(5px);
            }
        }

        .small {
            display: block;
            margin-top: 0.25rem;
            font-size: 0.75rem;
        }
    }

    &-subtitle {
        display: inline-block;
        margin: 0;
        text-align: right;
        line-height: 1.8;
        white-space: nowrap;
        color: $gray-600;
    }

    &-date {
        font-weight: 500;
    }

    &-toolbar {

        @media (max-width: (map-get($grid-breakpoints, sm) - 1)) {
            margin-top: 0.5rem;
        }

        .btn {
            position: relative;
            vertical-align: top;
            margin-right: 0.25rem;

            &:last-child {
                margin-right: 0;
            }

            i {
                margin-left: 0.25rem;
            }

            &-link {
                font-weight: 700;
                text-decoration: underline;
            }
        }

        h6 + &,
        .card-caption + & {
            margin-top: 0.5rem;

            .btn {
                margin-top: 0.5rem;
            }
        }
    }

    &-body {

        p:last-child {
            margin-bottom: 0;
        }
    }

    &-details {
        display: flex;
        justify-content: space-between;
        padding: 1rem 0;
        margin: 1rem 0;
        border-top: 1px solid $gray-500;
        border-bottom: 1px solid $gray-500;

        &:last-child {
            padding-bottom: 0;
            margin-bottom: 0;
            border-bottom: none;
        }
    }

    &-note {
        font-size: 0.875em;
        color: $gray-600;
    }

    &-link {
        padding: 0;
        margin: 0;
        text-decoration: underline;
        color: $color-link;
        background: transparent;
        border: none;
        outline: none;
        cursor: pointer;
    }

    h6:not([class]),
    dl {
        margin-bottom: 0;
    }

    .patient {
        display: flex;
        align-items: center;
        padding: 1rem;
        background: $navbar-bg;

        &-image {
            width: 5rem;
            height: 5rem;
            margin-right: 1rem;
            text-align: center;
            font-size: 2rem;
            line-height: 5rem;
            border-radius: 10rem;
            object-fit: cover;
        }

        &-name {
            margin: 0.25rem 0 0.5rem;
            font-weight: 700;

            &:last-child {
                margin: 0;
            }
        }

        &-status {
            margin-bottom: 0;
            font-size: 0.75rem;
            line-height: 1.25;
            color: $gray-600;

            @media (min-width: map-get($grid-breakpoints, md)) {
                font-size: 1rem;
            }
        }
    }

    .btn {

        &-opaque {
            opacity: 1 !important;
        }
    }
}