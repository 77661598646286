.folder-image {
    width: 35px;
    height: 35px;
}

i.ni {
    display: inline-block;
    width: 1em;
    height: 1em;
    font-size: 1em;
    text-align: center;

    &.ni-xs {
        font-size: .75em;
    }

    &.ni-sm {
        font-size: .875em;
    }

    &.ni-ml {
        font-size: 1.1em;
    }

    &.ni-lg {
        font-size: 1.25em;
    }

    &.ni-xl {
        font-size: 1.5em;
    }

    &.ni-2x {
        font-size: 2em;
    }

    &.ni-3x {
        font-size: 3em;
    }

    &.ni-fw {
        width: 1.25em;
    }

    &::after {
        content: "";
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        vertical-align: center;
        display: inline-block;
    }

    &.ni-arrow-down {
        &::after {
            background-image: url(../../../images/arrow-down.svg);
            width: 1em;
            height: 1em;
        }
    }

    &.ni-arrow-up {
        &::after {
            background-image: url(../../../images/arrow-up.svg);
            width: 1em;
            height: 1em;
        }
    }

    &.ni-blood-test {
        &::after {
            background-image: url(../../../images/blood-tests.svg);
            width: 0.755em;
            height: 1em;
        }
    }

    &.ni-doctor-visit {
        &::after {
            background-image: url(../../../images/doctor-visits.svg);
            width: 0.943em;
            height: 1em;
        }
    }

    &.ni-hospital-report {
        &::after {
            background-image: url(../../../images/hospital-reports.svg);
            width: 0.725em;
            height: 1em;
        }
    }

    &.ni-molecular-genomic {
        &::after {
            background-image: url(../../../images/molecular-genomic.svg);
            width: 1em;
            height: 1em;
        }
    }

    &.ni-imaging {
        &::after {
            background-image: url(../../../images/imaging.svg);
            width: 0.558em;
            height: 1em;
        }
    }

    &.ni-medications {
        &::after {
            background-image: url(../../../images/medications.svg);
            width: 0.865em;
            height: 1em;
        }
    }

    &.ni-pathology {
        &::after {
            background-image: url(../../../images/pathology.svg);
            width: 0.8em;
            height: 1em;
        }
    }

    &.ni-symptom-report {
        &::after {
            background-image: url(../../../images/symptom-reports.svg);
            width: 0.905em;
            height: 1em;
        }
    }

    &.ni-advocate-notes {
        &::after {
            background-image: url(../../../images/advocate-notes.svg);
            width: 1em;
            height: 1em;
        }
    }

    &.ni-lead-advocate-notes {
        &::after {
            background-image: url(../../../images/lead-advocate-notes.svg);
            width: 1em;
            height: 1em;
        }
    }

    &.ni-er-visit {
        &::after {
            background-image: url(../../../images/er-visit.svg);
            width: 1em;
            height: 1em;
        }
    }

    &.ni-arrow-circle-up {
        &::after {
            background-image: url(../../../images/arrow-circle-up.svg);
            width: 1em;
            height: 1em;
        }
    }

    &.ni-arrow-circle-down {
        &::after {
            background-image: url(../../../images/arrow-circle-down.svg);
            width: 1em;
            height: 1em;
        }
    }

    &.ni-star-solid {
        &::after {
            background-image: url(../../../images/star-solid.svg);
            width: 1em;
            height: 0.962em;
        }
    }

    &.ni-surgery-notes {
        &::after {
            background-image: url(../../../images/surgery-notes.svg);
            width: 1em;
            height: 1em;
        }
    }

    &.ni-other {
        &::after {
            background-image: url(../../../images/other.svg);
            width: 1em;
            height: 1em;
        }
    }

    &.ni-play-btn {
        &::after {
            background-image: url(../../../images/play-btn.svg);
            width: 1em;
            height: 1em;
        }
    }

    &.ni-pause-btn {
        &::after {
            background-image: url(../../../images/pause-btn.svg);
            width: 1em;
            height: 1em;
        }
    }

    &.ni-audio {
        &::after {
            background-image: url(../../../images/audio.svg);
            width: 1em;
            height: 0.933em;
        }
    }
    &.ni-external-link {
        content: url(../../../images/external-link-hover.svg);
        width: 33px;
        height: 33px;
        background-color: $color-purple;
        border: 1px solid $color-purple;
        padding-left: 7px;
        padding-right: 7px;
        padding-top: 3px;
        padding-bottom: 3px;
        width: 36px;
        height: 36px;
        border-radius: 6px;

        &:hover{
            color:#fff;
            background-color: white;
            content: url(../../../images/external-link.svg);
        }
    }
    &.ni-download {
        content: url(../../../images/download-hover.svg);
        width: 33px;
        height: 33px;
        background-color: $color-purple;
        border: 1px solid $color-purple;
        padding-left: 7px;
        padding-right: 7px;
        padding-top: 3px;
        padding-bottom: 3px;
        width: 36px;
        height: 36px;
        border-radius: 6px;

        &:hover {
            color: #fff;
            background-color: white;
            content: url(../../../images/download.svg);
        }
    }
    &.ni-avatar {
        content: url(../../../images/user-avatar.svg);
        width: 62px;
        height: 62px;
    }

}

.ni-plus {
    width: 32px;
    height: 32px;
    background-image: url(../../../images/plus-solid.svg);
    margin-right: 5px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;

    &:hover {
        transform: scale(1.2);
    }
}
