.share-file-dialog {
    padding: 30px 25px 25px 25px;
    font-family: Roboto;

    .share-file-headline {
        font-size: 20px;
        line-height: 1.45;
    }

    .assigned-title {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.38;
        letter-spacing: -0.38px;
    }

    .assigned-form-input {
        margin-top: 40px;

        textarea {
            width: 100%;
            height: 180px;
            border-radius: 6px;
            border: solid 1px #d2d2d2;
            padding: 6px 8px 6px 8px;

            &:focus {
                border: solid 1px #d2d2d2;
                outline: none !important;
            }
        }
    }

    .assigned-actions {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .assigned-users {
        margin-top: 20px;

        .assigned-users-container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            max-height: 190px;
            overflow-y: auto;

            .assigned-user {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 237px;
                height: 72px;
                margin: 10px 10px 10px 0px;
                padding: 10px 10px 10px 10px;
                border-radius: 10px;
                border: solid 1px #4a2ecf;
                cursor: pointer;

                &.selected {
                    background-color: rgba(74, 46, 207, 0.15);
                }

                .avatar {
                    width: 46px;
                    height: 46px;
                    background-color: #4a2ecf;
                    border-radius: 2rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .avatar-text {
                        color: white;
                        font-size: 22px;
                        font-weight: 500;
                        margin: auto;
                    }
                }

                .name-container {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    margin-left: 10px;

                    .name {
                        font-size: 20px;
                        line-height: 1.45;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        max-width: 160px;
                    }
                    .position {
                        font-size: 16px;
                        font-weight: 300;
                        line-height: 1.81;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        max-width: 160px;
                    }
                }
            }
        }
    }
}

.react-multi-email {
    border: solid 1px #d2d2d2 !important;
    border-radius: 6px;

    .added-email {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 3px 4px 3px 4px;
        border-radius: 17.5px;
        border: solid 1px $color-brown-grey;
        background-color: #fff;
        margin: 5px 5px 5px 5px;

        .email-text {
            margin: 0px 10px 0px 10px;
        }

        .delete-email {
            cursor: pointer;
        }
    }
}