form {

    .invalid-feedback {
        display: block;
    }
}

.form {

    label {
        user-select: none;
    }

    label:not(.form-check-label),
    .form-check-label:first-child {
        font-weight: 500;
        font-size: .75rem;
    }

    &-check {

        &-group {

            &-horizontal {
                display: flex;

                .form-label {
                    margin-right: 1.25rem;
                }

                .form-check {
                    margin-right: 1.25rem;

                    &:first-child {
                        padding-left: 0;
                    }
                }
            }

            &-vertical {

                .form-check {
                }
            }
        }

        & > &-label:first-child {
            margin-bottom: .25rem;

            &,
            & ~ .form-check-input {
                position: static;
                margin-left: -1.25rem;
            }
        }
    }

    br + .form-check-input {
        margin-left: 0;
    }

    textarea {
        min-height: 150px;
    }

    &-control {
        width: 100%;
        //border: 2px solid $page-bg;

        &:not(.form-control-rounded) {
            border-radius: 0;
        }

        &-dark {
            background: rgba(0, 0, 0, .025);
            border-color: $navbar-bg;
        }

        &-invalid {
            border-color: $color-red-dark !important;
        }
    }

    .react-datepicker-wrapper {
        width: 100%;
    }

    .react-datepicker__input-container {

        &::after {
            content: "\f133";
            position: absolute;
            top: 0;
            right: 0;
            height: 2.5rem;
            padding: 0 0.5rem;
            line-height: 2.5rem;
            font-size: 1.5rem;
            font-family: "Font Awesome 5 Free", Roboto, serif;
            color: $gray-300;
        }
    }
}

.form-group {
    margin-bottom: 1rem;
}

.form-group-flex {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

