.timeline {
  margin-top: 1rem;
  font-size: 0.75rem;

  * {
    transition: none !important;
  }

  &[data-density] {

    .vis-text.vis-major, .vis-text.vis-minor {
      opacity: 0
    }
  }

  &[data-density="0"] {
    .vis-text.vis-major, .vis-text.vis-minor {
      opacity: 1 !important
    }
  }

  .vis-text.vis-minor.vis-tomorrow,
  .vis-text.vis-minor.vis-today:nth-last-child(1) {
    opacity: 0 !important
  }

  @media (max-width: map-get($grid-breakpoints, md) - 1) {
    &[data-density="1"] {
      .vis-major, .vis-minor {
        &:nth-child(3n + 3) {
          opacity: 1
        }
      }
    }
    &[data-density="2"] {
      .vis-major, .vis-minor {
        &:nth-child(4n + 3) {
          opacity: 1
        }
      }
    }
    &[data-density="3"] {
      .vis-major, .vis-minor {
        &:nth-child(5n + 3) {
          opacity: 1
        }
      }
    }
    &[data-density="4"] {
      .vis-major, .vis-minor {
        &:nth-child(6n + 3) {
          opacity: 1
        }
      }
    }
    &[data-density="5"] {
      .vis-major, .vis-minor {
        &:nth-child(7n + 3) {
          opacity: 1
        }
      }
    }
    &[data-density="6"] {
      .vis-major, .vis-minor {
        &:nth-child(8n + 3) {
          opacity: 1
        }
      }
    }
    &[data-density="7"] {
      .vis-major, .vis-minor {
        &:nth-child(9n + 3) {
          opacity: 1
        }
      }
    }
    &[data-density="8"] {
      .vis-major, .vis-minor {
        &:nth-child(10n + 3) {
          opacity: 1
        }
      }
    }
  }
  @media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg) - 1) {
    &[data-density="1"] {
      .vis-major, .vis-minor {
        &:nth-child(2n + 3) {
          opacity: 1
        }
      }
    }
    &[data-density="2"] {
      .vis-major, .vis-minor {
        &:nth-child(3n + 3) {
          opacity: 1
        }
      }
    }
    &[data-density="3"] {
      .vis-major, .vis-minor {
        &:nth-child(4n + 3) {
          opacity: 1
        }
      }
    }
    &[data-density="4"] {
      .vis-major, .vis-minor {
        &:nth-child(4n + 3) {
          opacity: 1
        }
      }
    }
    &[data-density="5"] {
      .vis-major, .vis-minor {
        &:nth-child(6n + 3) {
          opacity: 1
        }
      }
    }
    &[data-density="6"] {
      .vis-major, .vis-minor {
        &:nth-child(6n + 3) {
          opacity: 1
        }
      }
    }
    &[data-density="7"] {
      .vis-major, .vis-minor {
        &:nth-child(7n + 3) {
          opacity: 1
        }
      }
    }
    &[data-density="8"] {
      .vis-major, .vis-minor {
        &:nth-child(8n + 3) {
          opacity: 1
        }
      }
    }
  }
  @media (min-width: map-get($grid-breakpoints, lg)) and (max-width: map-get($grid-breakpoints, xl) - 1) {
    &[data-density="1"] {
      .vis-major, .vis-minor {
        &:nth-child(2n + 3) {
          opacity: 1
        }
      }
    }
    &[data-density="2"] {
      .vis-major, .vis-minor {
        &:nth-child(3n + 3) {
          opacity: 1
        }
      }
    }
    &[data-density="3"] {
      .vis-major, .vis-minor {
        &:nth-child(3n + 3) {
          opacity: 1
        }
      }
    }
    &[data-density="4"] {
      .vis-major, .vis-minor {
        &:nth-child(3n + 3) {
          opacity: 1
        }
      }
    }
    &[data-density="5"] {
      .vis-major, .vis-minor {
        &:nth-child(5n + 3) {
          opacity: 1
        }
      }
    }
    &[data-density="6"] {
      .vis-major, .vis-minor {
        &:nth-child(6n + 3) {
          opacity: 1
        }
      }
    }
    &[data-density="7"] {
      .vis-major, .vis-minor {
        &:nth-child(7n + 3) {
          opacity: 1
        }
      }
    }
    &[data-density="8"] {
      .vis-major, .vis-minor {
        &:nth-child(8n + 3) {
          opacity: 1
        }
      }
    }
  }
  @media (min-width: map-get($grid-breakpoints, xl)) and (max-width: $breakpoint-xxl - 1) {
    &[data-density="1"] {
      .vis-major, .vis-minor {
        &:nth-child(2n + 3) {
          opacity: 1
        }
      }
    }
    &[data-density="2"] {
      .vis-major, .vis-minor {
        &:nth-child(3n + 3) {
          opacity: 1
        }
      }
    }
    &[data-density="3"] {
      .vis-major, .vis-minor {
        &:nth-child(3n + 3) {
          opacity: 1
        }
      }
    }
    &[data-density="4"] {
      .vis-major, .vis-minor {
        &:nth-child(3n + 3) {
          opacity: 1
        }
      }
    }
    &[data-density="5"] {
      .vis-major, .vis-minor {
        &:nth-child(4n + 3) {
          opacity: 1
        }
      }
    }
    &[data-density="6"] {
      .vis-major, .vis-minor {
        &:nth-child(4n + 3) {
          opacity: 1
        }
      }
    }
    &[data-density="7"] {
      .vis-major, .vis-minor {
        &:nth-child(5n + 3) {
          opacity: 1
        }
      }
    }
    &[data-density="8"] {
      .vis-major, .vis-minor {
        &:nth-child(6n + 3) {
          opacity: 1
        }
      }
    }
  }
  @media (min-width: $breakpoint-xxl) and (max-width: $breakpoint-xxxl - 1) {
    &[data-density="1"] {
      .vis-major, .vis-minor {
        &:nth-child(2n + 3) {
          opacity: 1
        }
      }
    }
    &[data-density="2"] {
      .vis-major, .vis-minor {
        &:nth-child(2n + 3) {
          opacity: 1
        }
      }
    }
    &[data-density="3"] {
      .vis-major, .vis-minor {
        &:nth-child(3n + 3) {
          opacity: 1
        }
      }
    }
    &[data-density="4"] {
      .vis-major, .vis-minor {
        &:nth-child(4n + 3) {
          opacity: 1
        }
      }
    }
    &[data-density="5"] {
      .vis-major, .vis-minor {
        &:nth-child(4n + 3) {
          opacity: 1
        }
      }
    }
    &[data-density="6"] {
      .vis-major, .vis-minor {
        &:nth-child(5n + 3) {
          opacity: 1
        }
      }
    }
    &[data-density="7"] {
      .vis-major, .vis-minor {
        &:nth-child(5n + 3) {
          opacity: 1
        }
      }
    }
    &[data-density="8"] {
      .vis-major, .vis-minor {
        &:nth-child(5n + 3) {
          opacity: 1
        }
      }
    }
  }
  @media (min-width: $breakpoint-xxxl) and (max-width: $breakpoint-xxxxl - 1) {
    &[data-density="1"] {
      .vis-major, .vis-minor {
        &:nth-child(1n + 3) {
          opacity: 1
        }
      }
    }
    &[data-density="2"] {
      .vis-major, .vis-minor {
        &:nth-child(1n + 3) {
          opacity: 1
        }
      }
    }
    &[data-density="3"] {
      .vis-major, .vis-minor {
        &:nth-child(1n + 3) {
          opacity: 1
        }
      }
    }
    &[data-density="4"] {
      .vis-major, .vis-minor {
        &:nth-child(2n + 3) {
          opacity: 1
        }
      }
    }
    &[data-density="5"] {
      .vis-major, .vis-minor {
        &:nth-child(2n + 3) {
          opacity: 1
        }
      }
    }
    &[data-density="6"] {
      .vis-major, .vis-minor {
        &:nth-child(3n + 3) {
          opacity: 1
        }
      }
    }
    &[data-density="7"] {
      .vis-major, .vis-minor {
        &:nth-child(4n + 3) {
          opacity: 1
        }
      }
    }
    &[data-density="8"] {
      .vis-major, .vis-minor {
        &:nth-child(5n + 3) {
          opacity: 1
        }
      }
    }
  }
  @media (min-width: $breakpoint-xxxxl) {
    &[data-density="1"] {
      .vis-major, .vis-minor {
        &:nth-child(1n + 3) {
          opacity: 1
        }
      }
    }
    &[data-density="2"] {
      .vis-major, .vis-minor {
        &:nth-child(1n + 3) {
          opacity: 1
        }
      }
    }
    &[data-density="3"] {
      .vis-major, .vis-minor {
        &:nth-child(1n + 3) {
          opacity: 1
        }
      }
    }
    &[data-density="4"] {
      .vis-major, .vis-minor {
        &:nth-child(1n + 3) {
          opacity: 1
        }
      }
    }
    &[data-density="5"] {
      .vis-major, .vis-minor {
        &:nth-child(2n + 3) {
          opacity: 1
        }
      }
    }
    &[data-density="6"] {
      .vis-major, .vis-minor {
        &:nth-child(2n + 3) {
          opacity: 1
        }
      }
    }
    &[data-density="7"] {
      .vis-major, .vis-minor {
        &:nth-child(3n + 3) {
          opacity: 1
        }
      }
    }
    &[data-density="8"] {
      .vis-major, .vis-minor {
        &:nth-child(4n + 3) {
          opacity: 1
        }
      }
    }
  }

  &-hidden {
    opacity: 0;
  }

  &-loader {
    margin-top: 2rem;
    text-align: center;

    & + div {
      opacity: 0;
    }
  }

  &-container {
    overflow: hidden;
  }

  &-range {
    position: relative;
    z-index: 2;
    margin-top: 1rem;

    .rc-slider {
      height: 0.75rem;
      padding: 0;

      &-rail {
        height: 0.75rem;
        background: $color-pale-grey;
      }

      &-track {
        height: 0.75rem;
        background: $color-grey-2;
      }

      &-handle {
        width: calc(0.75rem + 4px);
        height: calc(0.75rem + 4px);
        margin-top: -2px;
        background: $color-grey-2;
        border: 2px solid #5D5E5F !important;
        box-shadow: none !important;

        &:active {
          box-shadow: 0 0 0 5px #5D5E5F !important;
        }
      }

      &-mark-text {
        display: none;
      }

      &-step {
        height: 0.75rem;
      }

      &-dot {
        display: none;
      }
    }
  }

  .vis {

    &-timeline,
    &-panel.vis-top,
    &-panel.vis-center,
    &-panel.vis-bottom,
    &-panel.vis-left,
    &-panel.vis-right,
    &-label,
    &-group {
      border: none;
      margin-bottom: 5px;
    }

    &-panel.vis-center {
      overflow: visible;
    }

    &-time-axis {
      z-index: 1;
    }

    &-label,
    &-group {
      border-radius: 10px;
      &.graph-visible {
        height: 200px !important;
      }

      .vis-item {
        border-radius: 6px;
      }
    }

    &-text {

      &.vis-major,
      &.vis-minor {
        color: $gray-700;
      }

      &.vis-major {

        div {
          width: 3em;
          height: 2.4em;
          line-height: 1.2;
          white-space: break-spaces;
        }
      }

      &.vis-today {
        font-weight: 700;
      }
    }

    &-grid {

      &.vis-major {
        border-left: 1px solid transparent;
      }

      &.vis-minor {
        border-left: none;
      }
    }

    &-line {
      border-color: black;
    }

    &-label {
      padding-left: 0.25rem;
      text-align: left;
      background: $navbar-bg;
      border-bottom: 2px solid $page-bg !important;
      border-right: 2px solid $page-bg;

      &:first-child {
        background: transparent;
      }

      &.vis-nesting-group,
      &.vis-nested-group {

        &.blue {
          background: $color-sky;
          color: white;
        }

        &.purple {
          background: $color-purple;
          color: white;
        }

        &.red {
          background: $color-red;
          color: white;
        }

        &.red-dark {
          background: $color-red-dark;
          color: white;
        }

        &.green {
          background: $color-green;
          color: white;
        }

        &.black {
          background: $color-black;
          color: white;
        }

        &.grey-1 {
          background: $color-grey-1;
          color: white;
        }

        &.grey-2 {
          background: $color-grey-2;
          color: white;
        }

        &.grey-3 {
          background: $color-grey-3;
          color: white;
        }

        &.lightish-purple {
          background: $color-lightish-purple;
          color: white;
        }

        &.algae-green {
          background: $color-algae-green;
          color: white;
        }

        &.dusty-orange {
          background: $color-dusty-orange;
          color: white;
        }

        &.deep-sky-blue {
          background: $color-deep-sky-blue;
          color: white;
        }

        &.brownish-grey {
          background: $color-brownish-grey;
          color: white;
        }

        &.dark-indigo {
          background: $color-dark-indigo;
          color: white;
        }

        &.pinkish-red {
          background: $color-pinkish-red;
          color: white;
        }
      }

      &.vis-nesting-group {
        color: white;
        overflow: hidden;

        &::before {
          content: '\f067';
          padding: 5px;
          float: right;
          width: 1.5rem;
          text-align: center;
          font-weight: 700;
          font-family: "Font Awesome 5 Free";
        }

        &.expanded {
          font-weight: 700;

          &::before {
            content: '\f068';
          }
        }
      }

      &.vis-nested-group {

        .vis-inner {
          position: relative;
          width: 100%;
          padding-right: 2rem;
          padding-left: 5px !important;
        }

        i {
          position: absolute;
          top: 0;
          right: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 1.5rem;
          height: 30px;
          font-size: 1rem;
          color: white;
          background: $color-link;
          cursor: pointer;

          &.delete-icon {
            right: 30px;
            background: url(../../../images/delete.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 18px 17px;
          }

          &.chart-line {
            //right: 30px;
            background: url(../../../images/chart.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 17px 15px;
            background-color: $color-purple;
            border-radius: 6px;
            width: 30px;

            &.graph-visible {
              background: url(../../../images/chart-selected.svg) !important;
              background-repeat: no-repeat !important;
              background-position: center !important;
              background-size: 18px 17px !important;
              color: $color-purple;
              background-color: white !important;
              border-radius: 6px !important;
            }
          }
        }
      }
    }

    &-item {

      .vis-item-content {
        line-height: 1.3;
        padding-right: 5px;
      }
    }
  }

  &-events {
    position: relative;
    z-index: 1;
    padding-bottom: 1rem;
    background: $color-pale-grey;

    .vis {

      &-timeline,
      &-background,
      &-time-axis,
      &-time-axis .vis-text,
      &-panel.vis-center,
      &-panel.vis-background.vis-vertical {
        overflow: visible;
      }

      &-time-axis {
        height: 3em !important;
      }

      &-panel.vis-left {
        opacity: 0;
        visibility: hidden;
      }

      &-grid {

        &.vis-major {

          &::before {
            content: '';
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            height: 1000vh;
            background: $background-white;
            border-left: 1px dashed $color-grey;
          }

          &.vis-today::before {
            left: -1px;
          }
        }

        &.vis-today {
          position: relative;
          background: $color-sky-light;

          &::before,
          &::after {
            content: '';
            position: absolute;
            z-index: 0;
            left: -1px;
            right: 0;
            height: 100vh;
            background: $color-sky-light;
          }

          &::before {
            bottom: 100%;
          }

          &::after {
            top: 100%;
          }
        }
      }

      &-axis {
        z-index: 2;

        .vis-dot {

          &::after {
            content: '';
            position: absolute;
            top: calc(100% + 2px);
            left: 50%;
            width: 1px;
            height: 1.125rem;
            background: black;
            transform: translateX(-50%);
          }
        }
      }

      &-dot {
        width: 1rem;
        height: 1rem;
        border-radius: 100%;
        border: 2px solid $background-white;

        &.blue {
          background: $color-sky;
        }

        &.purple {
          background: $color-purple;
        }

        &.red {
          background: $color-red;
        }

        &.red-dark {
          background: $color-red-dark;
        }

        &.green {
          background: $color-green;
        }

        &.black {
          background: $color-black;
        }

        &.grey-1 {
          background: $color-grey-1;
        }

        &.grey-2 {
          background: $color-grey-2;
        }

        &.grey-3 {
          background: $color-grey-3;
        }

        &.diagnosis {
          background: $color-grey-2;
        }

        &.lightish-purple {
          background: $color-lightish-purple;
        }

        &.algae-green {
          background: $color-algae-green;
        }

        &.dusty-orange {
          background: $color-dusty-orange;
        }

        &.deep-sky-blue {
          background: $color-deep-sky-blue;
        }

        &.brownish-grey {
          background: $color-brownish-grey;
        }

        &.dark-indigo {
          background: $color-dark-indigo;
        }

        &.pinkish-red {
          background: $color-pinkish-red;
        }
      }

      &-box {
        width: 5rem;

        &.diagnosis {
          width: 7rem;
        }
      }

      &-box,
      &-range {
        text-align: left;
        font-weight: 700;
        background: white;
        border: none;
        border-top: 5px solid white;

        &.clickable {
          color: $color-link;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }

        &.blue {
          border-top-color: $color-sky;
        }

        &.purple {
          border-top-color: $color-purple;
        }

        &.red {
          border-top-color: $color-red;
        }

        &.red-dark {
          border-top-color: $color-red-dark;
        }

        &.green {
          border-top-color: $color-green;
        }

        &.black {
          border-top-color: $color-black;
        }

        &.grey-1 {
          border-top-color: $color-grey-1;
        }

        &.grey-2 {
          border-top-color: $color-grey-2;
        }

        &.grey-3 {
          border-top-color: $color-grey-3;
        }

        &.lightish-purple {
          border-top-color: $color-lightish-purple;
        }

        &.algae-green {
          border-top-color: $color-algae-green;
        }

        &.dusty-orange {
          border-top-color: $color-dusty-orange;
        }

        &.deep-sky-blue {
          border-top-color: $color-deep-sky-blue;
        }

        &.brownish-grey {
          border-top-color: $color-brownish-grey;
        }

        &.dark-indigo {
          border-top-color: $color-dark-indigo;
        }

        &.pinkish-red {
          border-top-color: $color-pinkish-red;
        }

        &.diagnosis {
          border: 1px solid $color-grey-3;
          background: $color-yellow;
          color: black;
        }

        &.transparent {
          opacity: .5;
        }

        .vis-item-content {
          padding: 3px;
          padding-right: 5px;
          white-space: normal;
          word-break: break-word;

          span {
            display: block;
            font-weight: 400;
            //white-space: pre;
            //overflow: auto;
          }

          .vis-item-link {
            display: inline-block;
            font-weight: 700;
            color: $color-link;
            text-decoration: none;
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      &-range {
        padding: 0 10px;
        background: transparent;
        border: none;
        border-bottom: 1px solid black;

        .vis-item-overflow {
          text-align: center;
          border-top: 5px solid white;
        }

        &.blue .vis-item-overflow {
          border-top-color: $color-sky;
        }

        &.purple .vis-item-overflow {
          border-top-color: $color-purple;
        }

        &.red .vis-item-overflow {
          border-top-color: $color-red;
        }

        &.red-dark .vis-item-overflow {
          border-top-color: $color-red-dark;
        }

        &.green .vis-item-overflow {
          border-top-color: $color-green;
        }

        &.black .vis-item-overflow {
          border-top-color: $color-black;
        }

        &.grey-1 .vis-item-overflow {
          border-top-color: $color-grey-1;
        }

        &.grey-2 .vis-item-overflow {
          border-top-color: $color-grey-2;
        }

        &.grey-3 .vis-item-overflow {
          border-top-color: $color-grey-3;
        }

        &.lightish-purple .vis-item-overflow {
          border-top-color: $color-lightish-purple;
        }

        &.algae-green .vis-item-overflow {
          border-top-color: $color-algae-green;
        }

        &.dusty-orange .vis-item-overflow {
          border-top-color: $color-dusty-orange;
        }

        &.deep-sky-blue .vis-item-overflow {
          border-top-color: $color-deep-sky-blue;
        }

        &.brownish-grey .vis-item-overflow {
          border-top-color: $color-brownish-grey;
        }

        &.dark-indigo .vis-item-overflow {
          border-top-color: $color-dark-indigo;
        }

        &.pinkish-red .vis-item-overflow {
          border-top-color: $color-pinkish-red;
        }

        .vis-item-content {
          left: 0 !important;
          margin: 0 auto 1rem;
          text-align: left;
          background: white;

          &::before {
            content: '';
            position: absolute;
            z-index: -1;
            top: 0;
            left: -100vw;
            right: -100vw;
            height: 100%;
            background: white;
          }
        }

        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          width: 1px;
          height: 1rem;
          background: black;
        }
      }
    }
  }

  &-analytes {
    position: relative;
    z-index: 1;
    border-top: 1px solid black;
    background-color: $white;

    .vis {

      &-time-axis {
        overflow: visible;
      }

      &-panel.vis-left .vis-labelset {
        position: relative;
        z-index: 1;
        padding-top: 1rem;
        margin-right: 2rem;
        background: white;
      }

      &-content .vis-itemset {

        .vis-background,
        .vis-foreground {
          padding-top: 1rem;
        }

        .vis-label {
          border-radius: 10px;
        }
      }

      &-panel.vis-center .vis-background .vis-group {
        background: $color-pale-grey;
        box-shadow: -2rem 1px 0px -1px $color-pale-grey;

        &.main,
        &:first-child,
        &:nth-child(2) {
          background: transparent;
          box-shadow: none;
        }

        &:nth-child(2) ~ .vis-group {
          border-top: 2px solid $page-bg;
        }
      }

      &-text.vis-minor {
        padding-top: 0.5rem;
        overflow: visible;
      }

      &-dot {
        background: transparent;
        border-color: transparent;
      }

      &-item {

        .vis-item-content {
          padding: 5px 7px 5px 3px;
          text-align: right;

          span {
            display: inline-block;
            min-width: 1.275em;
            text-align: left;
          }
        }
      }

      &-box {
        min-width: 2rem;
        text-align: center;
        background: white;
        border: none;
        border-radius: 0;
        box-shadow: -0.125rem 0 0.25rem rgba(0, 0, 0, .2);

        &.decimal {
          min-width: 3.5em;
        }

        &.round {
          min-width: 0;
          text-align: center;
          border-radius: 100%;
        }

        &.purple {
          line-height: 1;
          color: white;
          background: $color-purple;
          border: none;
        }

        &.warning {

          &::before {
            content: '!';
            position: absolute;
            top: 50%;
            left: 0;
            width: 1rem;
            height: 1rem;
            text-align: center;
            font-weight: 700;
            color: white;
            background: $color-red-light;
            border-radius: 100%;
            transform: translateX(-75%) translateY(-50%);
          }
        }

        &.down,
        &.up {

          .vis-item-content::after {
            content: '\f063';
            margin-left: 0.25rem;
            font-weight: 700;
            font-family: "Font Awesome 5 Free";
            opacity: 0.6;
          }
        }

        &.up {

          .vis-item-content::after {
            content: '\f062';
          }
        }

        i {
          width: 1em;
          font-size: 1.25rem;
        }
      }

      &-group.graph-visible .vis-box {
        opacity: 0;
      }
    }
  }
}

.vis-label {
  .visible-only {
    display: none;
    background-color: white;
    width: 120px;
    padding: 10px;
    margin-top: 20px;
  }

  &.graph-visible .visible-only {
    display: block !important;
  }

}

.analytes-view {
  //width: 250px;
  height: 30px;
  z-index: 2;
  position: absolute;
  top: -70px;
  left: 10px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  .card-title {
    font-size: 16px;
  }
}

.add-analytes-button {
  z-index: 2;
  position: absolute;
  top: 8px;
  left: 0px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  background-color: white;
  border-radius: 10px;
}

.analytes-list {
  height: 50vh;
  border: solid 1px #c4c4c4;
  align-content: flex-start;

  .added-analyte {
    min-height: 49px;
    margin: 5px 0 5px 0px;
    width: 95%;
    padding: 11.5px 12.7px 13.5px 19.3px;
    border-radius: 6px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;

    .delete-analyte {
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
    }
  }

  .analyte-text {
    cursor: pointer;
  }
}

.analytes-list-title {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.add-analytes-title {
  font-family: Roboto;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  justify-content: center;
  color: #000;
  background-color: #eef0f0;
}

.analytes-button {
  width: 101px;
  height: 42px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  background-color: #fff;

  &.save-analytes {
    border-radius: 6px;
    background-color: #5d31f2;
    color: #fff;
  }

  &.cancel-analytes {
    border-radius: 6px;
    border: solid 1px #5d31f2;
    color: #5d31f2;
  }
}
