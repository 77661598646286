.audio-player {
    display: inline-flex;
    align-items: center;

    background-color: #fff;

    .audio-player-progress {
        display: inline-flex;
        gap: 6px;
        width: 100%;
        justify-content: center;
        align-items: center;

        .progress-small {
            width: 100px;
            height: 0.5rem;
            cursor: pointer;

            .progress-bar {
                background-color: #4830d3 !important;
            }
        }

        .progress-big {
            width: 200px;
            height: 0.5rem;
            cursor: pointer;

            .progress-bar {
                background-color: #4830d3 !important;
            }
        }

        .audio-player-time {
            font-size: 1rem;
            color: #707070;
            font-weight: 500;
            width: 4ch;

            &:first-child {
                text-align: right;
            }
        }
    }

    .play-btn {
        display: flex;
        align-self: baseline;
        margin-left: 10px;

        button {
            border: 0;
            padding: 0;
            background-color: transparent;
            background-size: contain;
            background-repeat: no-repeat;
            width: 34px;
            height: 34px;

            &.play {
                background-image: url(../../../images/play-btn.svg);
            }

            &.pause {
                background-image: url(../../../images/pause-btn.svg);
            }
        }
    }
}

.meeting-recording {
    display: inline-flex;
    align-items: center;
    gap: 9px;

    .audio-icon {
        background-image: url(../../../images/audio.svg);
        background-size: contain;
        background-repeat: no-repeat;
        width: 16px;
        height: 16px;
    }

    .audio-label {
        font-size: 1rem;
        font-weight: 500;
        letter-spacing: -0.5px;
    }
}