.panel {
    position: fixed;
    z-index: 1000;
    top: 0;
    right: 0;
    bottom: 0;
    display: none;
    width: 100%;
    background: white;
    overflow: hidden;

    &-open {
        display: flex;
        flex-direction: column;
    }

    @media (min-width: map-get($grid-breakpoints, sm)) {
        width: 320px;
    }

    &-header {
        width: 100%;
        height: 66px;
        padding: 14px 16px 14px 20px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        background-color: #edefef;
    }

    &-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.5rem;

        h4 {
            margin: 0;
        }

        button {
            width: 2rem;
            padding: 0;
            font-size: 1.5rem;
        }
    }

    &-toolbar {
        display: flex;
        align-content: flex-end;
        flex-wrap: wrap;
        justify-content: flex-end;

        &-notification {
            margin-right: 0.5rem;

            &.btn {
                margin-right: 1rem;
                border-radius: 100%;
                position: relative;
                background-Color: #eef0f0;
                color: #707070;
                font-size: 1.2rem;
            }
        }

        &-button {
            padding: 0;
            width: 2rem;
            height: 2rem;
            margin-right: 0.5rem;
            border-radius: 100%;

            &.active {
                background: $gray-300;
            }
        }
    }

    &-search {

        input {
            background: $gray-100;
        }
    }

    &-body {
        overflow-y: auto;
        overscroll-behavior: none;

        &-loading {
            text-align: center;
        }

        &-empty {
            margin: 1rem;
        }
    }
    
    &-loader {
        width: 100%;
        padding: 2rem 0;
        text-align: center;
    }
}