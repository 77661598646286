.graph {
    position: absolute;
    z-index: 1000;
    right: 0;
    display: block;
    height: 198px;
    background: white;

    &:empty {
        display: none;
    }
}