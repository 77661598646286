@import 'bootstrap';
@import "~react-toastify/dist/ReactToastify.css";
@import '~react-phone-number-input/style.css';

@import 'components/variables';
@import 'components/base';
@import 'components/typography';
@import 'components/form';
@import 'components/form-check';
@import 'components/button';
@import 'components/badge';
@import 'components/table';
@import "components/list";
@import "components/icons";
@import 'components/layout';
@import 'components/login';
@import "components/auth";
@import 'components/navbar';
@import 'components/patient-bar';
@import 'components/patient-menu';
@import 'components/action-menu';
@import 'components/card';
@import 'components/modal';
@import 'components/panel';
@import 'components/details';
@import 'components/dials';
@import 'components/timeline';
@import 'components/graph';
@import 'components/report';
@import 'components/calendar';
@import 'components/event';
@import 'components/notes';
@import 'components/tasks';
@import 'components/messages';
@import 'components/activity';
@import 'components/document';
@import "components/biomarkers";
@import "components/support-circle";
@import "components/audio-player";
@import "components/auth-code-input";
@import "components/input";
@import "components/tooltip";
@import "components/tabs";
@import "components/link-upload-files";
@import "components/share-file-dialog";