body {
    background: $page-bg;
}

main {
    padding: 1rem 0;
}

.container,
.container-fluid {
    padding-left: 1rem;
    padding-right: 1rem;
}

.row {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
}

.col {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.layout {

    &-patient {
        padding-bottom: 2.5rem;

        @media (min-width: map-get($grid-breakpoints, lg)) {
            padding-bottom: 0;
            padding-right: 2.5rem;
        }
        
        &-search {
            padding-right: 0;
        }

        &-panel-open {
            overflow: hidden;
        }

        &-home {
        }

        &-document {
            padding: 0 !important;

            object,
            iframe {
                display: block !important;
                width: 100% !important;
                height: calc(100vh - 56px) !important;
                padding: 0 !important;
                margin: 0 !important;
            }
        }

        &-timeline {

            @media (min-width: map-get($grid-breakpoints, lg)) {

                .col {
                    display: flex;
                    flex-direction: column;
                }

                .card {
                    flex-grow: 1;
                    margin-bottom: 0;

                    &-scrolling {
                        position: absolute;
                        top: 0;
                        left: 0.5rem;
                        right: 0.5rem;
                        height: 100%;
                        overflow-y: auto;
                        overscroll-behavior: none;
                    }
                }
            }
        }
    }
}