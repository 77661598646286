.link-container {
    padding-left: 5rem;
    padding-right: 5rem;
}

.document {
    overflow-y: auto;
    overscroll-behavior: none;

    &-loading &-content {
        opacity: 0;
    }

    &-toolbar {
        position: sticky;
        z-index: 1000;
        top: 0;
        padding: 0.5rem 0;
        background: $gray-700;

        [class*="col"] {
        }

        &-pagination {
            display: flex;
            justify-content: center;

            .input-group {
                width: auto;
            }

            .input-group-prepend {
                margin-right: 0;
            }

            .input-group-append {
                margin-left: 0;
            }
        }

        &-search {
            display: flex;
            justify-content: flex-end;

            &-value  {
                position: relative;
            }

            &-counter {
                position: absolute;
                top: 0;
                right: 0;
                padding: 0.5rem;
                font-size: .75rem;
                opacity: .5;
                cursor: default;
                pointer-events: none;
            }
        }

        &-button {
        }

        &-input {
            min-width: 0;
            max-width: none;

            &-page {
                width: 3rem !important;
                text-align: center;
            }

            &-search {
                width: 10rem !important;
            }
        }

        input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {-webkit-appearance: none; margin: 0;}
        input[type=number] {-moz-appearance: textfield;}
    }

    &-content {
        overflow-x: auto;
    }

    &-search {

        &-result {
            display: inline-block;
            background: yellow;
            outline: 2px solid yellow;

            &-current {
                background: orange;
                outline: 2px solid orange;
                animation: pop 0.1s ease-out;
            }

            &-noanimation {
                animation: none !important;
            }
        }
    }

    .react-pdf {

        &__Document {
            text-align: center;
        }

        &__Page {
            margin: 0 auto;
            overflow: hidden;

            &:not(:last-child) {
                margin-bottom: 1rem;
            }

            &__canvas,
            &__svg {
                margin: 0 auto;
                background: white;
                box-shadow: 0 0 0.25rem rgba(0, 0, 0, .1);
            }

            &__textContent {
                user-select: none;
                color: transparent;
                mix-blend-mode: multiply;
            }

            &--overzoom .react-pdf__Page__textContent {
                top: 0 !important;
                left: 0 !important;
                transform: none !important;
            }

            &__annotations {
                user-select: none;
            }
        }
    }

    &-scaled {

        .react-pdf {

            &__Page {
                margin: 1rem auto;
            }
        }
    }
}

@keyframes pop {
    0% {transform: scale(3)}
    100% {transform: scale(1)}
}


.document-view {

    &-loading &-content {
        opacity: 0;
    }

    &-toolbar {
        padding: 0.5rem 0;
        background: $gray-700;

        [class*="col"] {
        }

        &-pagination {
            display: flex;
            justify-content: center;

            .input-group {
                width: auto;
            }

            .input-group-prepend {
                margin-right: 0;
            }

            .input-group-append {
                margin-left: 0;
            }
        }

        &-search {
            display: flex;
            justify-content: flex-end;

            &-value  {
                position: relative;
            }

            &-counter {
                position: absolute;
                top: 0;
                right: 0;
                padding: 0.5rem;
                font-size: .75rem;
                opacity: .5;
                cursor: default;
                pointer-events: none;
            }
        }

        &-button {
        }

        &-input {
            min-width: 0;
            max-width: none;

            &-page {
                width: 3rem !important;
                text-align: center;
            }

            &-search {
                width: 10rem !important;
            }
        }

        input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {-webkit-appearance: none; margin: 0;}
        input[type=number] {-moz-appearance: textfield;}
    }

    &-content {
        min-height: 30vh;
        height: calc(100vh - 56px);
        overflow-y: auto;
        overscroll-behavior: none;
    }

    &-search {

        &-result {
            display: inline-block;
            background: yellow;
            outline: 2px solid yellow;

            &-current {
                background: orange;
                outline: 2px solid orange;
                animation: pop 0.1s ease-out;
            }

            &-noanimation {
                animation: none !important;
            }
        }
    }

    .react-pdf {

        &__Document {
            text-align: center;
        }

        &__Page {
            margin: 0 auto;
            overflow: hidden;

            &:not(:last-child) {
                margin-bottom: 1rem;
            }

            &__canvas,
            &__svg {
                margin: 0 auto;
                background: white;
                box-shadow: 0 0 0.25rem rgba(0, 0, 0, .1);
            }

            &__textContent {
                user-select: none;
                color: transparent;
                mix-blend-mode: multiply;
            }

            &--overzoom .react-pdf__Page__textContent {
                top: 0 !important;
                left: 0 !important;
                transform: none !important;
            }

            &__annotations {
                user-select: none;
            }
        }
    }

    &-scaled {

        .react-pdf {

            &__Page {
                margin: 1rem auto;
            }
        }
    }
}

.document-info-toolbar {
    width: 100%;
    padding: 8px 14px;
    position: relative;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: #edefef;
    gap: 1rem;

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }

    .info-left {
        display: inline-flex;
        align-items: center;
        gap: 0.8rem;

        .prop {
            display: inline-flex;
            flex-direction: column;

            .name {
                font-size: 0.9rem;
                font-weight: 400;
            }

            .value {
                font-weight: 500;
                max-width: 10rem;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }
    }

    .info-right {
        display: inline-flex;
        align-items: center;
        flex-direction: row;
        gap: 0.8rem;
    }

    .btn.icon-link {
        padding: 0.2rem;
        i {
        }
    }
}

.document-info-toolbar {
    &.info-controls {
        background-color: #fff;
    }
}

.upload-files-header {
    display: flex;
    flex-direction: row;
    align-items: stretch;

    .header-logo {
        display: flex;
        background-color: #4830d3;
        align-items: center;
        padding: 20px;
        margin: 0px;

        img {
            height: 75px;
        }
    }

    .header-info {
        display: flex;
        flex-direction: column;
        width: 100%;

        .document-info-toolbar {
            padding: 13px 13px 13px 30px;
        }
    }

    .patient-menu-info {
        background-color: #edefef;
        padding: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        .container {
            background-color: $page-bg;
        }

        .patient-menu-details {
            font-size: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 10px;
        }

        .patient-menu-image {
            width: 4rem;
            border-right: 10px;
        }

        .patient-menu-detail {
            flex-grow: 0;

            &-full {
                width: 100%;
                flex-basis: 100%;
                flex-shrink: 0;
                font-weight: 700;
            }

            &::after {
                content: '|';
                margin-left: 0.25em;
                margin-right: 0.25em;
            }

            &-full,
            &:last-child {

                &::after {
                    display: none;
                }
            }
        }

    }
}

.react-pdf__Page {
    width: auto !important;
}

.link-uploader {
    padding-top: 0rem;

    .info-line {
        font-size: 18px;
        line-height: 1;
    }

    .col {
        padding: 30px;
    }

    .form-group {
        margin-top: 30px;
    }

    .link-shadow {
        box-shadow: 2px 0 10px 0 rgba(0, 0, 0, 0.23);
    }

    .invalid-feedback {
        display: block;
    }

    &-input {
        display: flex;
        margin-top: 1rem;
        padding: 22px 31px 21px 27px;
        border-radius: 6px;
        border: solid 1px $color-purple;
        font-size: 20px;
        line-height: 1.55;
        align-items: center;

        .btn {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            flex-grow: 0;
            padding: 0;
            width: 3rem;
            height: 3rem;
            margin-right: 1rem;
            border-radius: 100px;

            span {
                position: relative;
                font-size: 2.5rem;
            }
        }

    }

    &-box {
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        align-items: stretch;
        min-height: 50vh;
        border: 1px solid $gray-500;

        &-dragging {
            background: $gray-100;
            border-style: dashed;
        }

        &-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 1.5rem;
            font-size: 28px;
            font-weight: 500;

            .btn {
                padding: 0;
            }
        }

        &-title {
            margin: 0;
        }

        &-files {
            flex-grow: 1;
            max-height: 300px;
            overflow-y: auto;
            overflow-x: hidden;

            > p {
                padding: 0 1.5rem;
            }

            table {
                width: 100%;
                font-size: 0.875rem;
                color: $gray-500;
                table-layout: fixed;
                word-wrap: anywhere;

                progress {
                    width: 100%;
                }

                tr {

                    &:nth-child(odd) {
                        background: $gray-200;
                    }
                }

                td {
                    padding: 0.25rem 0.5rem;
                    color: black;

                    &:first-child {
                        width: 2em;
                        padding-left: 1rem;
                    }

                    &:nth-last-child(2),
                    &:last-child {
                        text-align: right;
                    }

                    &:last-child {
                        width: 3em;
                        padding-right: 1rem;
                    }

                    .msg {
                        font-size: 14px;
                        font-weight: 500;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.29;
                        letter-spacing: normal;
                        text-align: left;
                        color: #000;
                    }
                }

                h6 {
                    margin: 0;
                    font-weight: 400;
                    font-size: inherit;
                    color: black;

                    &.error {
                        color: $color-pinkish-red;
                    }
                }

                .btn {
                    padding: 0;
                    text-decoration: none;
                    color: white;
                    border: none;
                    border-radius: 10em;
                    width: 25px;
                    height: 25px;
                    background: white;

                    &.btn-success {
                        background: #25BB66;
                        opacity: 1 !important;
                    }

                    span {
                        display: block;
                        height: 1.25rem;
                        line-height: 1.25rem;
                        font-size: 0.875rem;
                    }
                }
            }

            .completed-files-title {
                margin: 43px 35px 17px 35px;
                font-size: 20px;
                font-weight: 500;
                line-height: 1.5;
            }
        }

        &-submit {
            padding: 1.5rem;
            text-align: center;
            border-top: 1px solid $gray-500;
            display: flex;
            justify-content: space-between;

            &-message {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .left {
                display: flex;
                justify-content: stretch;
                align-items: center;
                text-align: left;
                margin-right: 1rem;
            }

            .right {
                text-align: left;
            }

            .badge {
                width: 2rem;
                height: 2rem;
                padding: 0;
                margin-right: 0.5rem;
                font-size: 1.5rem;
                line-height: 2rem;
                border-radius: 10em;

                &-white {
                    color: $color-link;
                }

                &-success {
                    width: 40px;
                    height: 40px;
                }
            }

            h6 {
                margin-bottom: 0.25rem;
                font-size: 1.125rem;
                white-space: nowrap;
            }

            p {
                margin: 0;
                font-size: 0.625rem;
            }
        }
    }
}

.btn-success {
    background: #25BB66 !important;
    opacity: 1 !important;
}

.uploader-box-dragging {
    border-width: 8px;
    border-color: $color-purple;
    border-style: solid;
}

.link-uploader-box {
    border-radius: 6px;
}

.openai {
    background-color: $color-deep-sky-blue-20;
}
