.messages {

    &-contacts {
        padding: 2rem 1rem 0;

        &-type {
            color: $color-link;
        }
    }

    &-title {
        margin: 0;

        &:first-child {
            padding-left: 1rem;
        }
    }

    &-back,
    &-close {
        flex: 0 0 3rem;
        width: 3rem;
        height: 3rem;
        line-height: 1;
        font-size: 1.25rem;
        color: inherit !important;

        &:hover,
        &:focus {
            opacity: .5;
        }

        i {
            width: 1rem;
        }
    }

    &-close {
        margin-left: auto;
    }

    &-contact {
        position: relative;
        display: flex;
        justify-content: stretch;
        align-items: center;
        padding: 1rem 0;
        border-top: 1px solid $gray-500;
        cursor: pointer;

        &:last-child {
            padding-bottom: 0;
        }
    }

    &-unread {
        position: absolute;
        z-index: 1;
        top: 1rem;
        left: 0;
        width: 1rem;
        height: 1rem;
        text-align: center;
        font-size: 0.675em;
        font-weight: 700;
        line-height: 1rem;
        color: white;
        background: red;
        border-radius: 100%;
    }

    &-avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 2rem;
        width: 2rem;
        height: 2rem;
        margin-right: 0.5rem;
        background: $gray-300;
        border-radius: 100rem;
        cursor: pointer;
        overflow: hidden;

        img {
            width: 2rem;
            height: 2rem;
        }
    }

    &-details {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        flex-wrap: wrap;
        flex-grow: 1;
        overflow: hidden;

        &-name {
            font-size: 1rem;
            font-weight: 700;
        }

        &-subject {
            font-size: 0.875rem;
            font-weight: 700;
        }

        &-date {
            font-size: 0.75rem;
            color: $color-link;
        }

        &-message {
            flex-basis: 100%;
            width: 100%;
            margin: 0;
            font-weight: 700;
            font-size: 0.75rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            span {
                font-weight: 400;
            }
        }
    }

    &-messages {
        padding: 2rem 1rem 1rem;

        &-title {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 1rem;

            h5 {
                margin: 0;
                font-weight: 500;
            }

            button {
                padding: 0;
                margin-right: 1rem;
            }
        }
    }

    &-message {
        padding: 1rem 0;
        font-size: 0.75rem;
        border-top: 1px solid $gray-500;

        &-title {
            display: flex;
            justify-content: flex-start;
            align-items: baseline;
            margin-bottom: 0.5rem;

            span {
                margin-right: 0.5rem;
            }
        }

        &-name {
            font-size: 0.875rem;
            font-weight: 700;
        }
    }
}