.report {
    margin-top: 1rem;
    font-size: 0.625rem;

    &-loader {
        margin-top: 2rem;
        text-align: center;

        & + div {
            opacity: 0;
        }
    }

    &-container {
        padding: 2rem 1rem;
        background: $background-white;
        overflow: hidden;

        div:empty,
        table:empty {
            display: none;
        }
    }

    &-item {
        display: flex;
        font-size: 1rem;

        &:not(:last-child) {
            padding-bottom: 2rem;
            margin-bottom: 2rem;
            border-bottom: 2px solid $color-grey-3;
        }

        > div {
            padding: 0 1rem;

            @media (min-width: map-get($grid-breakpoints, xl)) {
                padding: 0 1.5rem;
            }
        }
    }

    &-caption {
        display: flex;
        align-items: center;
    }

    &-categories {
        display: flex;
        min-width: 3.5em;
        margin-right: 1rem;
        justify-content: flex-end;
        align-items: center;
    }

    &-category {
        width: 1rem;
        height: 1rem;
        margin: 0 0.25rem 0 0;
        border-radius: 1rem;

        &-lab {background: $color-purple;}
        &-imaging {background: $color-green;}
        &-intervention {background: $color-red;}
        &-pro {background: $color-sky;}
        &-md {background: $color-red-dark;}
        &-hospitalvisits {background: $color-black;}

        &:last-child {
            margin-right: 0;
        }
    }

    &-date {
        min-width: 3em;
        margin: 0;
    }

    &-title {
        margin-bottom: 0;
    }

    &-subtitle {
        margin-bottom: 0.5rem;
    }

    &-table {

        table {

            tr {
                border-bottom: 1px solid $color-grey-2;

                &:last-child:not(:only-child) {
                    border-bottom: none;
                }
            }

            td {
                padding: 0.125rem 0.5rem;
                white-space: nowrap;

                @media (min-width: map-get($grid-breakpoints, xl)) {
                    min-width: 7em;
                }
            }
        }
    }

    &-description {

    }
}