ul.file-group-list {
    padding: 0;
    list-style: none;

    li {
        padding: 0.4rem 0.2rem 0.4rem 0.3rem;
        cursor: pointer;
        border-bottom: 1px solid rgba(60, 60, 67, 0.36);;
        height: 45px;

        &:hover {
            background-color: rgba(180, 250, 255, 0.5);
        }

        i {
        }

        .file-group-name {
            display: inline-block;
            margin-left: 2rem;
            font-size: 1.1rem;
            font-weight: 500;
            -ms-transform: translateY(10%);
            transform: translateY(10%);
        }

        .file-group-count {
            float: right;
            font-weight: 400;
            color: rgba(60, 60, 67, 0.6);
            -ms-transform: translateY(15%);
            transform: translateY(15%);
        }

    }
}