.login {
    min-height: 100vh;
    color: #000000;
    background: #f2f9ff;

    &__left-panel {
        padding: 40px;
        display: flex;
        flex-direction: column;
        background-color: white;
    }

    &__right-panel {
        z-index: 1;
        background-color: #4830d3;
        background-image: url(../../../images/nixio_logo_empty.svg);
        background-position: right 0;
        background-size: 130%;
        background-repeat: no-repeat;
    }

    &__medical-research_img {
        background-image: url(../../../images/undraw_medical_research.svg);
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 70%;
        height: 100%;
        width: 100%;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    &__header-logo {
        background-image: url(../../../images/logo-purple.svg);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 130px;
        height: 104px;
        display: inline-block;
    }

    &__header-text {
        display: inline-block;
        font-size: 26px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        margin-left: 25px;
    }

    &__form-container {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__welcome-msg {
        font-size: 36px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
    }

    &__form {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__username {
        margin-top: 60px;
        max-width: 350px;
        width: 350px;
    }

    &__password-container {
        margin-top: 20px;
        max-width: 350px;
        width: 350px;
        position: relative;
    }

    &__auth-error {
        text-align: center;
        margin-top: 2rem;
        font-size: 16px;
        font-family: Roboto;
    }

    &__forgot-password {
        margin-top: 26px;
        cursor: pointer;
        color: $color-purple;
        transition: 0.2s;
        &:hover {
            transition: 0.2s;
            transform: scale(1.1);
        }
    }

    &__submit {
        margin-top: 80px;
        width: 150px;
    }

    &__footer {
        font-size: 14px;
        text-align: center;
        font-weight: 400;
        margin-bottom: 40px;
    }
}