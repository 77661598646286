:root {
    --toastify-z-index: 999999999;
}

html, body, #root {
    height: 100%;
}

body {
    margin: 0;
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    text-decoration: none;
}

.cursor-pointer {
    cursor: pointer;
}

.col-no-right-border {
    border-right: 0px !important;
    padding-right: 0px !important;
}
.col-no-left-border {
    border-left: 0px !important;
    padding-left: 0px !important;
}

.patient-files-container {
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.font-weight-500 {
    font-weight: 500;
}

.ni-search {
    position: relative;
    flex-grow: 1;

    & > input {
        padding-right: 2rem;
    }

    & > i {
        position: absolute;
        margin: 0;
        right: 0.5rem;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
}

.vertical-separator {
    width: 1px;
    height: calc(100% - 10px);
    background-color: black;
    margin-top: 5px;
    margin-bottom: 5px;
}

.left-right-margin-100 {
    margin-left: 100px;
    margin-right: 100px;
    padding: 20px;
}
