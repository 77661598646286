.biomarkers-overview {
    background-color: #eef0f0;
    padding: 15px;
    border-radius: 10px;

    .section {
        padding: 0 15px 10px 15px;
        border-right: solid 1px #2b2c2c;

        &:last-child {
            border-right: none;
        }

        h1, h2, h3, h4, h5, h6 {
            margin-bottom: 0;
        }

        h5 {
            font-size: 1.25rem;
        }

        h6 {
            display: inline-block;
            font-size: 1rem;
            line-height: 1.25rem;
            vertical-align: baseline;
        }

        p {
            margin-top: 0.5rem;
        }
    }
}

.biomarkers-headline {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 48px;
    margin-bottom: 12px;

    .headline-title {
        font-size: 1.25em;
        font-weight: bold;
    }

    .headline-document {

    }
}

table.biomarkers {
    table-layout: fixed;

    tbody {
        border: none;
    }

    tr {
        &.section-header {
            background-color: #eef0f0;
            cursor: pointer;

            td {
                padding: 4px 0 8px 12px;
                border-bottom: none;
                font-size: 0.9375em;
                font-weight: 500;
            }
        }
    }

    th, td {
        border-top: none;
    }

    th {
        padding: 8px 0;
        font-size: 0.9375em;
        border-bottom: 2px solid #2b2c2c;
    }

    td {
        padding: 10px 0;
        font-size: 1em;
        border-bottom: 1px solid #2b2c2c;
    }
}