.event {
    position: absolute;
    z-index: 1000;
    width: 300px;
    padding: 0 0.5rem;
    opacity: 0;

    &-visible {
        opacity: 1;
    }

    &-inner {
        width: 100%;
        height: 100%;
        padding: 0 1rem;
        background: white;
        border-radius: 0.25rem;
        box-shadow: $box-shadow;
        overflow: hidden;
    }

    &-toolbar {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 1rem;
        border-bottom: 1px solid $color-grey-3;

        .btn {
            padding: 0.5rem;
            color: inherit;

            &:last-child {
                margin: 0 -0.5rem 0 0;
            }

            &-edit {color: $color-link;}
            &-delete {color: $color-red-dark;}


            &:hover,
            &:focus {
                opacity: .5;
            }
        }
    }

    &-title {
        padding-left: 2rem;
        margin-bottom: 1rem;
        text-indent: -2rem;
        //text-transform: capitalize;
        font-weight: 700;
        
        &-imaging {
            text-transform: none;
        }

        &::before {
            content: '';
            position: relative;
            top: 2px;
            display: inline-block;
            width: 1rem;
            height: 1rem;
            margin: 0 0.5rem;
            background: white;
            border-radius: 1rem;
        }

        &-lab::before {background: $color-purple;}
        &-imaging::before {background: $color-green;}
        &-intervention::before {background: $color-red;}
        &-pro::before {background: $color-sky;}
        &-md::before {background: $color-red-dark;}
        &-hospitalvisits::before {background: $color-black;}
    }

    &-date,
    &-description,
    &-alarm {
        margin-bottom: 1rem;
        font-size: 0.75rem;

        &:empty {
            display: none;
        }
    }

    &-date,
    &-description {
        padding-left: 2rem;
    }

    &-caption {
        position: relative;

        &-overflowing::before {
            content: '';
            position: absolute;
            z-index: 1;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2rem;
            background: linear-gradient(transparent, white);
        }
    }

    &-description {
        max-height: 12em;
        overflow-y: auto;
        overscroll-behavior: none;

        &-overflowing {
            padding-bottom: 2rem;
        }

        span {
            display: block;
        }
    }

    &-alarm {

        i {
            width: 1rem;
            margin: 0 0.5rem;
            font-size: 1rem;
        }
    }
}