.task-photo {
    flex-shrink: 0;
    flex-grow: 0;
    display: block;
    width: 2.5rem;
    height: 2.5rem;
    padding: 0;
    margin: 0 auto;
    text-align: center;
    line-height: calc(2.5rem - 4px);
    color: $gray-500;
    border: 2px solid $gray-500;
    border-radius: 100%;
    cursor: pointer;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    font-family: SFProDisplay;

    &-private.active {
        background: $color-magenta-light;
        border-bottom-color: $color-purple;

        i {
            color: $color-purple !important;
            border-color: $color-purple !important;
        }
    }

    &-public.active {
        background: $color-sky-light;
        border-bottom-color: $color-link;

        i {
            color: $color-link !important;
            border-color: $color-link !important;
        }
    }

    &.administrator,&.back_office,&.la,&.ca {
        background-color: #b947f9;
        color: #fff;
    }

    &.oa,&.sa,&.patient_manager {
        background-color: #ffa200;
        color: #fff;
    }

    &.patient {
        background-color: #25bb66;
        color: #fff;
    }
}


.tasks {

    &-new {
        font-weight: 700;
    }

    &-header {
        display: flex;
        justify-content: stretch;
        align-items: center;
        padding: 1.5rem 0.5rem 0;

        .btn {
            //width: 2rem;
            //padding: 0;
            margin-right: 0.5rem;
        }

        h5 {
            margin: 0;
        }
    }

    &-query {
        display: flex;
        padding: 0.8rem 1rem;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

        &-assignees {
            display: flex;
            align-items: center;
            overflow-y: auto;
            overscroll-behavior: none;
            justify-content: flex-start;
            border-right: solid 1px #707070;

            .assignees-heading {
                font-weight: bold;
                margin-right: 0.5rem;
                margin-bottom: 0.3rem;
                white-space: nowrap
            }

            .assignees-container {
                display: flex;
                margin-bottom: 0.3rem;

                .tasks-query-assignees-assignee {
                    margin-right: 0.25rem;
                }
            }

            &-assignee {
                flex-basis: 15%;
                flex-grow: 0;
                flex-shrink: 0;
                padding: 0;
                text-align: center;
                background: transparent;
                border: none;
                outline: none !important;
                opacity: .3;

                &-me {
                    order: -1;
                }

                i {
                    width: 2.5rem;
                    height: 2.5rem;
                    line-height: calc(2.5rem - 4px);
                    color: $gray-500;
                    border: 2px solid $gray-500;
                    border-radius: 100%;
                }

                h6 {
                    margin-top: 0.5rem;
                    font-weight: 500;
                    font-size: 0.875rem;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                &.active {
                    opacity: 1;
                }

                *:last-child {
                    margin-bottom: 0;
                }
            }
        }
        &-actions {
            //flex-basis: 30%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex-wrap: nowrap;
            min-width: 127px;
            padding-left: 1rem;
            margin-left: auto
        }
    }

    &-list {
        padding: 0 1rem;

        &-title {
            margin-top: 2rem;
            text-transform: uppercase;
            font-weight: 400;
            font-size: .875rem;

            &-inbox {
                padding: 0 1rem;
                text-transform: none;
                font-weight: 700;
                font-size: 1rem;
                color: $color-link;
            }
        }
    }

    &-heading {
        display: flex;
        padding: 0 1rem;
        margin: 0.25rem 0 0.5rem;

        &-title {
            cursor: pointer;
            flex-basis: 50%;
            text-align: center;
            font-size: 1.25rem;
            color: #707070;
            border-bottom: solid 1px;

            &.active {
                border-bottom: solid 4px;
                color: #5d31f2;
                font-weight: bold;
            }
        }
    }

    .task {
        display: flex;
        justify-content: stretch;
        align-items: center;
        padding: 0.5rem;
        margin: 0.5rem 0;
        background-color: #eef0f0;
        border-left: 7px solid #08f;
        border-radius: 6px;
        font-weight: bold;

        &-viewed {
            border-left: 0;
            font-weight: inherit;
        }

        &-heading {
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-grow: 1;
            padding: 0 0.5rem;
            flex-wrap: wrap;

            &-title {
                margin-bottom: 0.25rem;
                cursor: pointer;
                width: 60%;

                &-viewed {
                    font-weight: 400;
                }
            }

            &-date {
                font-size: .75rem;
                line-height: 1;
                color: $gray-600;
                cursor: pointer;
                width: 30%;

                &-overdue {
                    font-weight: 500;
                    color: #f51842;
                }
            }

            &-actions {
                width: 10%;
                &-element {
                    position: relative;
                    float: right;
                }
            }
        }

        &-button {
            flex-grow: 0;
            padding: 0;
            margin: 0;
            font-size: 1.75rem;
            line-height: 1;
            font-weight: 400;
            color: $gray-400;
            background: transparent;
            border: none;
            outline: none !important;
            
            &:disabled {
                cursor: not-allowed;
            }
        }

        *:last-child {
            margin-bottom: 0;
        }
    }

    .task-details {

        &-header {
            width: 100%;
            display: flex;

            h5 {
                margin-right: auto;

                i {
                    margin: 0 0 0 0.5rem;
                    font-size: 1.25rem;
                }

                span {
                    margin-left: 0.5rem;
                    font-weight: 400;
                    font-size: 0.875rem;
                    color: $gray-500;
                }
            }

            .task-button {
                font-size: 1rem;

                &:not(:last-child) {
                    margin-right: 0.5rem;
                }
            }

            .nav {
                display: flex;
                align-items: center;
                flex-basis: 50%;
            }

            .actions {
                display: flex;
                align-items: center;
                flex-basis: 50%;
                justify-content: flex-end;
            }
        }

        &-date {
            color: $gray-500;
        }

        &-content {
            padding: 2rem 1rem 0;

            .task-photo {
                margin: 0 0 1rem;
                cursor: default;
            }
        }

        &-comments {
            margin-top: 2rem;
            font-size: .875rem;

            h6 {
                margin-bottom: 1rem;
            }
        }

        &-created {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-top: 2rem;
            font-size: .875rem;

            h6 {
                flex-basis: 100%;
                margin-bottom: 1rem;
            }

            .task-photo {
                margin: 0 0.5rem 0 0;
                cursor: default;
            }

            p {
                margin: 0;
            }
        }
    }

    &-button {
        &-group {
            display: inline-flex;

            .btn {
                flex-grow: 1;
                margin-left: 0.75rem;
            }
        }
    }

    &-filter {
        align-items: center;
        display: flex;
        //flex-basis: 20%;
        padding: 0 1rem;
    }
}

/* Add task modal */
.form-group-assignees {
    display: flex;

    label {
        order: -2;
    }

    .me {
        order: -1;
    }

    .form-check {
        margin-bottom: 0.2rem;

        input {
            margin-top: 0.75rem;
            margin-left: -0.9rem;
        }
    }
}

.form-group-photo {
    display: flex;
    padding-left: 0.5rem;

    span {
        padding-left: 0.5rem;
    }
}

.task-notification-modal {
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 3px 3px 22px 0 rgba(0, 0, 0, 0.16);
    min-width: 25vw;
    min-height: 30vh;
    max-height: 70vh;

    button.btn-close {
        position: absolute;
        right: 0;
        top: 0;
        background-color: transparent;
        border-width: 0;

        &:focus {
            box-shadow: none;
        }

        i {
            vertical-align: middle;
            font-size: 1.6em;
            color: #aaaaaa;

            &:hover {
                color: darken(#aaaaaa, 15%);
            }
        }
    }

    .header {
        padding: 1rem 1rem;
        h3 {
            font-weight: bold;
        }
    }

    .body {
        padding: 1rem 1rem;
        overflow-y: scroll;
        overflow-x: hidden;
        max-height: 60vh;
    }

    .tasks-list {
        padding: 0;
    }
}

.task-notification-pivot {
    right: 25%;
    top: 25%;
    position: absolute;
}

.validation-feedback {
    font-size: 0.8rem;
    text-align: right;
}

.btn-rnd {
    border-radius: 6px;

    i{
        padding-right: 0.5rem;
    }
}

.btn-save {
    color: #ffffff !important;
    border-color: $color-purple !important;
    background-color: $color-purple !important;
    &:hover {
        color: #ffffff !important;
        background-color: transparentize($color-purple, 0.3) !important;
    }
}

.btn-cancel {
    color: $color-purple;
    border-color: $color-purple;
    background-color: #ffffff;
    &:hover {
        color: #ffffff;
        background-color: $color-purple;
    }
}

.btn-del {
    color: #de0000;
    background-color: #ffffff;
    border-color: #de0000;
}

.btn-filter {
    color: #0088ff;
    background-color: rgba(0, 136, 255, 0.2);
    border-radius: 18px;
    border-color: #0088ff;

    &:focus {
        box-shadow: none;
    }

    &.clicked {
        color: #ffffff;
        background-color: #0088ff;
    }
}

button:disabled {
    .task-photo {
        cursor: default;
    }
}