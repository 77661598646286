.badge {

    &-square {
        border-radius: 0;
    }
}
.badge-primary {
    margin: 2px 2px 2px 8px !important;
    padding: 4px 9px 4px;
    border-radius: 4px;
    background-color: $color-purple;
}

.badge-red {
    position: absolute !important;
    top: -0.5rem !important;
    right: -0.5rem !important;
    border-radius: 100%;
    background-color: $color-pinkish-red;
    font-size: 0.7rem;
}

.btn-with-badge {
    position: relative;
}