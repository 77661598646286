.nixio-tabs {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    .tabs-row {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        align-items: center;

        .tab-column {
            width: 50%;
            text-align: center;

            button {
                width: 75%;
                display: inline-block;
            }

        }

        .divider {
            width: 50%;
            height: 1px;
            background-color: $color-brownish-grey;

            &.selected {
                height: 4px;
                background-color: $color-timeline-selected;
            }
        }
    }
}
