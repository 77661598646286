.details {

    &-title {
        padding-bottom: 0.5rem;
        margin-bottom: 0.5rem;
        border-bottom: 1px solid black;
    }

    div {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 1rem;

        &:last-child {
            margin-bottom: 0;
        }
    }

    dt {
        flex-shrink: 0;
        margin: 0 0.25rem 0 0;
        font-weight: 700;
    }

    &-medical-id dt {
        width: 10em;
    }

    &-disease-summary dt {
        min-width: 6em;
    }

    &-molecular dt {
        min-width: 4em;
    }

    &-notes dt {
        min-width: 3em;
    }

    &-notes-modal dt {
        min-width: 8.33333%;
    }

    dd {
        margin: 0;
    }
}